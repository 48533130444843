.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.bg {
  background-color: #ffffff !important;
}

.input-group {
  position: relative !important;
  display: flex !important;
  flex-wrap: wrap !important;
  align-items: stretch !important;
  margin-right: 30px!important;
 
}
.search-notfound{
 font-size: 15px;
 font-weight:400px;
 margin-left:40%;
 
}



.forgot-btn{
    margin: 0px auto 8px auto;
    color: #fff;
    padding: 5px 0;
    background: #e53935;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 400;
    display: inline-block;
    width: 60px;
    text-align: center;
    border: 2px solid #e53935;
    transition: all 0.5s ease-out;
}

.list-type{
  list-style-type: square;
  line-height: 50px;

}
.sug{
  font-weight: 400px;
  margin-left:-34px;
}
.d-name{
  margin-left: 22px;
  margin-top: -20px;
}

.btg-red{
  background-color: red!important;
  color: #fff !important;
  border-radius: 50px;
  font-size: 14px !important;
  
}

 NavLink:active{

  color:black
}

.bg-backgound{
  background-color: #ffffff!important;
  font-size: 13px;
}

  



@meida(min-width:576px){
  .login-media{
    max-width: 720px;
  }
}

.media .media-body1 {
  margin-left: 30px !important;
  margin-top: 0px !important;
}

.text-align{
  
    margin-left: 20px;
    margin-top: 3px;

}

.form-list{
  
    margin-bottom: 1rem;
    width: 100%!important;
}


#searchbox{
  width: 200px!important;
  outline: none;
}

.search-notfound{
  text-align:'center';
  font-size:15px;
}


.single-img{
  height: auto;
  width: 100%;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-direction: column !important;
  flex-flow: column !important;
  margin-bottom: -3px !important;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login {
  margin-right: 46px;
}
.carousel .slide {
  margin-bottom: 0px;
  width: 100% !important;
  background-color: #f8f8f8 !important;
}

.slide img {
  margin-top: 0px;
}

.dropbtn {
  color: black;
  padding: 4px;
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0);
  z-index: 1;
  background-color: rgba(255, 0, 0, 0.664);
}

/* Links inside the dropdown */
.dropdown-content a {
  color: #0000009c;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.alignment {
  padding-right: 20px;
}

#header {
  border-bottom: 1px solid;
}

.bottom {
  border-bottom: 1px solid #ffffff !important;
}

p1 {
  width: 800px;
  white-space: pre;
  font-size: 13px;
  overflow-x: hidden;
  position: relative;
  animation: example 8s infinite;
  animation-direction: reverse;
}

@keyframes example {
  0% {
    left: 0px;
    top: 0px;
  }
  25% {
    left: 200px;
    top: 0px;
  }
}

@keyframes modal-video {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px);
  }
  to {
    transform: translate(0, 0);
  }
}
.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
.modal-video-effect-exit {
  opacity: 0;
}
.modal-video-effect-exit .modal-video-movie-wrap {
  -webkit-transform: translate(0, 100px);
  -moz-transform: translate(0, 100px);
  -ms-transform: translate(0, 100px);
  -o-transform: translate(0, 100px);
  transform: translate(0, 100px);
}
.modal-video-body {
  max-width: 940px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: table;
}
.modal-video-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}
.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}
.modal-video-movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -35px;
  right: -35px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent;
}
.modal-video-close-btn:before {
  transform: rotate(45deg);
}
.modal-video-close-btn:after {
  transform: rotate(-45deg);
}
.modal-video-close-btn:before,
.modal-video-close-btn:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #fff;
  border-radius: 5px;
  margin-top: -6px;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.p-1 {
  margin-left: 110px !important;
}

/* .dropdown-menu{
  width:300px !important;
  height: 350px!important;
  border-radius: 10px !important;


} */


.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}



.btn-cover {
  display: inline-block;
  font-weight: 600;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border:none;
  /* background-color: transparent; */
  border: 0px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


.reg {
  height: auto;
  overflow-y: hidden;
}

#hover {
  cursor: pointer;
}
#img {
  cursor: pointer;
}

/* .carousel.carousel-slider {
  height: 460px;
} */
#border {
  height: 1px;
  width: 5px;
}

#search-box {
  margin: 5px;
  padding: 8px;
}

.input-field {
  width: 150px;
  height: 25px;
  border: 1px solid #070707;
  border-radius: 2px;
  outline: none;
  overflow: hidden;
}

.icon {
  margin-left: -23px;
  padding-bottom: 0px;
  height: 25px;
  width: 22px;
  color: black;
}

.burger-menu {
  margin: 10px;
}

#menu-bars {
  width: 30px;
  height: 30px;
}
Menu {
  color: white;
}

#img {
  background-image: url("../src/assets/img/banner/breadcrumbs-banner.jpg");
}

.btn-secondary {
  color: black !important;
  background-color: #fff !important;
  /* border-color: none !important; */
  outline: none !important;
}
