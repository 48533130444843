body.ie .featuredContainer > div {
    width: 100%;
    box-sizing: border-box;
}
body.ie .d-inline-block {
    display: block!important;
}

body.ie .ie-full-width {
    width: 100%;
}
body.ie .post-date-dark ul li span {
    float: inherit;
}