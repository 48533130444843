@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");
html {
  background: #fff;
  font-size: 62.5%;
}
@media only screen and (max-width: 767px) {
  html {
    font-size: 50%;
  }
}


body {
  font-size: 13px !important;
  line-height: 24px;
  font-family: "Poppins", sans-serif !important;
  vertical-align: baseline;
  background-color: #e6e8eb;
  color: #444;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4 {
  line-height: 1.4;
  font-family: "Poppins", sans-serif;
  margin: 0 0 20px;
  color: #111;
  text-transform: capitalize;
}
h1 {
  font-size: 24px;
}
h2 {
  font-size: 20px;
}
h3 {
  font-size: 16px;
}
p {
  line-height: 24px;
  margin: 0 0 20px;
}
a,
a:active,
a:focus,
a:hover {
  text-decoration: none;
}
a:active,
a:focus,
a:hover {
  outline: 0;
}
img {
  max-width: 100%;
  height: auto;
}
ul {
  list-style: none none;
  margin: 0;
  padding: 0;
}
h2.size-sm {
  font-size: 18px;
}
h2.size-lg {
  font-size: 22px;
}
h2.size-xl {
  font-size: 24px;
}
h3.size-sm {
  font-size: 14px;
}
h3.size-md {
  font-size: 15px;
}
h3.size-lg {
  font-size: 18px;
}
p.size-sm {
  font-size: 15px;
}
p.size-lg {
  font-size: 17px;
}
.size-c22 {
  font-size: 22px;
}
.size-c24 {
  font-size: 24px;
}
.size-c26 {
  font-size: 26px;
}
.size-c30 {
  font-size: 30px;
}
.size-c34 {
  font-size: 34px;
}
.size-c36 {
  font-size: 36px;
}
.size-c40 {
  font-size: 40px;
}
.size-c60 {
  font-size: 60px;
}
h1.title-medium-dark {
  font-weight: 500;
  color: #111;
}
h1.title-medium-dark a {
  color: #111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
h1.title-medium-dark a:hover {
  color: #e53935;
}
h1.title-medium-light {
  font-weight: 500;
  color: #fff;
}
h1.title-medium-light a {
  color: #fff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
h1.title-medium-light a:hover {
  color: #e53935;
}
h2.title-light-dark {
  font-weight: 300;
}
h2.title-light-dark a {
  color: #111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
h2.title-light-dark a:hover,
h2.title-light-dark span {
  color: #e53935;
}
h2.title-regular-dark {
  font-weight: 400;
}
h2.title-regular-dark a {
  color: #111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
h2.title-regular-dark a:hover,
h2.title-regular-dark span {
  color: #e53935;
}
h2.title-medium-dark {
  font-weight: 500;
}
h2.title-medium-dark a {
  color: #111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
h2.title-medium-dark a:hover,
h2.title-medium-dark span {
  color: #e53935;
}
h2.title-semibold-dark {
  font-weight: 600;
}
h2.title-semibold-dark a {
  color: #111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
h2.title-semibold-dark a:hover,
h2.title-semibold-dark span {
  color: #e53935;
}
h2.title-bold-dark {
  font-weight: 700;
}
h2.title-bold-dark a {
  color: #111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
h2.title-bold-dark a:hover,
h2.title-bold-dark span {
  color: #e53935;
}
h2.title-light-light {
  font-weight: 300;
  color: #fff;
}
h2.title-light-light a {
  color: #fff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
h2.title-light-light a:hover,
h2.title-light-light span {
  color: #e53935;
}
h2.title-regular-light {
  font-weight: 400;
  color: #fff;
}
h2.title-regular-light a {
  color: #fff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
h2.title-regular-light a:hover,
h2.title-regular-light span {
  color: #e53935;
}
h2.title-medium-light {
  font-weight: 500;
  color: #fff;
}
h2.title-medium-light a {
  color: #fff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
h2.title-medium-light a:hover,
h2.title-medium-light span {
  color: #e53935;
}
h2.title-semibold-light {
  font-weight: 600;
  color: #fff;
}
h2.title-semibold-light a {
  color: #fff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
h2.title-semibold-light a:hover,
h2.title-semibold-light span {
  color: #e53935;
}
h2.title-bold-light {
  font-weight: 700;
  color: #fff;
}
h2.title-bold-light a {
  color: #fff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
h2.title-bold-light a:hover,
h2.title-bold-light span {
  color: #e53935;
}
h3.title-light-dark {
  font-weight: 300;
}
h3.title-light-dark a {
  color: #111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
h3.title-light-dark a:hover,
h3.title-light-dark span {
  color: #e53935;
}
h3.title-regular-dark {
  font-weight: 400;
}
h3.title-regular-dark a {
  color: #111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
h3.title-regular-dark a:hover,
h3.title-regular-dark span {
  color: #e53935;
}
h3.title-medium-dark {
  font-weight: 500;
}
h3.title-medium-dark a {
  color: #111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  font-size: 14px;
}
h3.title-medium-dark a:hover,
h3.title-medium-dark span {
  color: #e53935;
}
h3.title-semibold-dark {
  font-weight: 600;
}
h3.title-semibold-dark a {
  color: #111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
h3.title-semibold-dark a:hover,
h3.title-semibold-dark span {
  color: #e53935;
}
h3.title-bold-dark {
  font-weight: 700;
}
h3.title-bold-dark a {
  color: #111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
h3.title-bold-dark a:hover,
h3.title-bold-dark span {
  color: #e53935;
}
h3.title-light-light {
  font-weight: 300;
  color: #fff;
}
h3.title-light-light a {
  color: #fff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
h3.title-light-light a:hover,
h3.title-light-light span {
  color: #e53935;
}
h3.title-regular-light {
  font-weight: 400;
  color: #fff;
}
h3.title-regular-light a {
  color: #fff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
h3.title-regular-light a:hover,
h3.title-regular-light span {
  color: #e53935;
}
h3.title-medium-light {
  font-weight: 500;
  color: #fff;
}
h3.title-medium-light a {
  color: #fff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
h3.title-medium-light a:hover,
h3.title-medium-light span {
  color: #e53935;
}
h3.title-semibold-light {
  font-weight: 600;
  color: #fff;
}
h3.title-semibold-light a {
  color: #fff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
h3.title-semibold-light a:hover,
h3.title-semibold-light span {
  color: #e53935;
}
h3.title-bold-light {
  font-weight: 700;
  color: #fff;
}
h3.title-bold-light a {
  color: #fff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
h3.title-bold-light a:hover,
h3.title-bold-light span {
  color: #e53935;
}
p.text-light-dark {
  font-weight: 300;
  color: #111;
}
p.text-regular-dark {
  font-weight: 400;
  color: #111;
}
p.text-medium-dark {
  font-weight: 600;
  color: #111;
}
p.text-bold-dark {
  font-weight: 700;
  color: #111;
}
p.text-light-light {
  font-weight: 300;
  color: #fff;
}
p.text-regular-light {
  font-weight: 400;
  color: #fff;
}
p.text-medium-light {
  font-weight: 600;
  color: #fff;
}
p.text-bold-light {
  font-weight: 700;
  color: #fff;
}
p.text-light-primary {
  font-weight: 300;
  color: #e53935;
}
p.text-regular-primary {
  font-weight: 400;
  color: #e53935;
}
p.text-medium-primary {
  font-weight: 600;
  color: #e53935;
}
p.text-bold-primary {
  font-weight: 700;
  color: #e53935;
}
.color-dark {
  color: #111;
}
.color-light {
  color: #fff;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-lowercase {
  text-transform: lowercase;
}
.position-static {
  position: static;
}
.position-relative {
  position: relative;
}
.overflow-hidden {
  overflow: hidden;
}
.display-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}
ul.item-inline {
  overflow: hidden;
}
ul.item-inline li {
  margin-right: 3px;
  float: left;
  display: inline-block;
  margin-bottom: 3px;
}
ul.item-inline2 {
  overflow: hidden;
}
ul.item-inline2 li {
  margin-right: 3px;
  float: left;
  display: inline-block;
  margin-bottom: 3px;
}
ul.item-inline2 li:last-child {
  margin-right: 0;
}
ul.item2-inline li {
  display: inline-block;
  width: 49%;
  margin-right: 2px;
}
ul.item2-inline li:last-child {
  margin-right: 0;
}
.item-clear:after {
  clear: both;
  content: "";
  display: block;
}
.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
  
}
.row.no-gutters > [class*=" col-"],
.row.no-gutters > [class^="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.row.no-gutters.full-width {
  margin-right: -15px;
  margin-left: -15px;
}
.row.no-gutters.full-width > [class*=" col-"],
.row.no-gutters.full-width > [class^="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.line-height-custom {
  line-height: 1.35;
}
.media {
  margin-top: 0;
}

.navbar {
  padding :0px;
}


.img-25 {
  width : 100% !important;
  display: block;
}

.img-25 img {
  width: 100%;
  height: 135px;
  overflow: hidden;
}



.slide img {

  height : 418px;
  width : 100%;

}

.media-sm  {
  width: 180px;
 
  margin-right : 10px;
}
.media-sm  img {
  height : 90px;
  width : 100%;
}


.media-smm  {
  width: 100%;
 margin-bottom: 20px;
}
.media-smm  img {
  height : 150px;
  width : 100%;
}

.media-cat {
  width : 75%  !important; 
 
}

.media-md {
  height : 230px;
  width : 100%;
}

.sports-sm {
  width : 100%;
  height : 130px;
  
}

.text-scroll {
  width: 100%;
  white-space: nowrap;
  font-size: 15px;
  
}

.videos-slider  {
  width : 100%;
  height : 220px;
  
}
.card-img-overlay {
  background : rgba(0,0,0,0.5) ;
}

.videos_content {
  position: absolute !important; 
  bottom : 10px;
  content : '',
}

.recent-side {

  width: 100%;
  height : 100px;
}

.short-img{
  width: 185px;
  height: 85px;
    position: relative;
    display: block;
}
.media .media-body {
  margin-left: 200px;
  margin-top: -83px;
}
.media-photo .media-body {
  margin-left: 120px;
  margin-top: -73px;
}

.img-pro {
  max-width: 100% !important;
  height: 80px !important;
}
.media .media-body.media-margin30 {
  margin-left: 30px;
}
.media .media-padding15 {
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}
.media .media-body.media-padding30 {
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  margin-left: 30px;
}
.media .media-padding10 {
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 373px !important;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: none !important;

}
.media .media-padding8 {
  padding-top: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
}
.media .media-padding5 {
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}
.tab-space1 {
  margin-left: -1px;
  margin-right: -1px;
}
.tab-space1 > div {
  padding: 0 1px;
}
.tab-space2 {
  margin-left: -2px;
  margin-right: -2px;
}
.tab-space2 > div {
  padding: 0 2px;
}
.tab-space3 {
  margin-left: -3px;
  margin-right: -3px;
}
.tab-space3 > div {
  padding: 0 3px;
}
.tab-space4 {
  margin-left: -4px;
  margin-right: -4px;
}
.tab-space4 > div {
  padding: 0 4px;
}
.tab-space5 {
  margin-left: -5px;
  margin-right: -5px;
}
.tab-space5 > div {
  padding: 0 5px;
}
.tab-space8 {
  margin-left: -8px;
  margin-right: -8px;
}
.tab-space8 > div {
  padding: 0 8px;
}
.tab-space9 {
  margin-left: -9px;
  margin-right: -9px;
}
.tab-space9 > div {
  padding: 0 9px;
}
.tab-space10 {
  margin-left: -10px;
  margin-right: -10px;
}
.tab-space10 > div {
  padding: 0 10px;
}
.border-all {
  border: 1px solid #dcdcdc;
}
.border-bottom {
  position: relative;
}
.border-bottom:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  width: 100%;
  height: 1px;
}
.border-bottom-child > * {
  margin-bottom: 20px;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 15px;
}
.border-bottom-child > :last-child {
  margin-bottom: 0 !important;
  border-bottom: none;
  padding-bottom: 0 !important;
}
.top50 {
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
}
.bg-primary {
  background-color: #e53935 !important;
}
.bg-body {
  background-color: #fff;
}
.bg-body-color {
  background-color: #444;
}
.bg-secondary-body {
  background-color: #1f1f1f;
}
.bg-accent {
  background-color: #f8f8f8;
}
.bg-secondary-accent {
  background-color: #e6e8eb;
}
.bg-primarytextcolor {
  background-color: #111;
}
.bg-primarytextcolor p {
  color: #fff;
}
.overlay-dark {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.overlay-dark:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.overlay-dark-level-2 {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.overlay-dark-level-2:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
.bg-common {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-attachment {
  background-attachment: fixed;
}
#scrollUp {
  background-color: rgba(229, 57, 53, 0.3);
  bottom: 5px;
  color: #fff;
  display: block;
  font-size: 18px;
  height: 44px;
  line-height: 40px;
  position: fixed;
  right: 20px;
  text-align: center;
  text-decoration: none;
  width: 44px;
  z-index: 99999;
  border: 2px solid #e53935;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  -ms-transition: all 1s ease-out;
  -o-transition: all 1s ease-out;
  transition: all 1s ease-out;
}
#scrollUp i {
  color: #e53935;
}
#scrollUp:focus,
#scrollUp:hover {
  background-color: #e53935;
}
#scrollUp:focus i,
#scrollUp:hover i {
  color: #fff;
}
#scrollUp {
  animation: blinker 10s linear infinite;
}
#preloader {
  /* background: url(img/preloader.gif) center center no-repeat #e53935; */
  height: 100%;
  left: 0;
  overflow: visible;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999999;
}
.img-popup-icon {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 110%;
  -webkit-transform: translateY(-110%);
  -moz-transform: translateY(-110%);
  -ms-transform: translateY(-110%);
  transform: translateY(-110%);
  left: 0;
  right: 0;
  z-index: 8;
  margin: 0 auto;
  text-align: center;
  background-color: rgba(229, 57, 53, 0.95);
  height: 55px;
  width: 55px;
  line-height: 55px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  font-size: 24px;
  -webkit-transition: all 0.7s cubic-bezier(0, 0, 0.25, 1.5);
  -moz-transition: all 0.7s cubic-bezier(0, 0, 0.25, 1.5);
  -ms-transition: all 0.7s cubic-bezier(0, 0, 0.25, 1.5);
  -o-transition: all 0.7s cubic-bezier(0, 0, 0.25, 1.5);
  transition: all 0.7s cubic-bezier(0, 0, 0.25, 1.5);
}
.img-popup-icon:hover {
  color: #e53935;
  background-color: rgba(255, 255, 255, 0.95);
}
.img-popup-icon-layout2 {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  -webkit-transform: translateY(-40%);
  -moz-transform: translateY(-40%);
  -ms-transform: translateY(-40%);
  transform: translateY(-40%);
  top: 40%;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  left: 0;
  right: 0;
  z-index: 8;
  margin: 0 auto;
  text-align: center;
  height: 50px;
  width: 50px;
  line-height: 50px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.9);
  color: #e53935;
  font-size: 24px;
  -webkit-box-shadow: 0 1px 10px 5px rgba(225, 225, 225, 0.5);
  -moz-box-shadow: 0 1px 10px 5px rgba(225, 225, 225, 0.5);
  box-shadow: 0 1px 10px 5px rgba(225, 225, 225, 0.5);
  -webkit-transition: all 0.7s cubic-bezier(0, 0, 0.25, 1.5);
  -moz-transition: all 0.7s cubic-bezier(0, 0, 0.25, 1.5);
  -ms-transition: all 0.7s cubic-bezier(0, 0, 0.25, 1.5);
  -o-transition: all 0.7s cubic-bezier(0, 0, 0.25, 1.5);
  transition: all 0.7s cubic-bezier(0, 0, 0.25, 1.5);
}
.img-popup-icon-layout2:hover {
  background-color: rgba(229, 57, 53, 0.9);
  color: #fff;
  -webkit-box-shadow: 0 1px 10px 5px rgba(229, 57, 53, 0.5);
  -moz-box-shadow: 0 1px 10px 5px rgba(229, 57, 53, 0.5);
  box-shadow: 0 1px 10px 5px rgba(229, 57, 53, 0.5);
}
.popup-icon-hover {
  position: relative;
}
.popup-icon-hover:hover .img-popup-icon {
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
  opacity: 1;
  visibility: visible;
}
.popup-icon-hover:hover .img-popup-icon-layout2 {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}
.img-opacity-hover {
  position: relative;
  display: block;
}
.img-opacity-hover:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(225, 225, 225, 0.2);
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.img-opacity-hover:hover:after {
  opacity: 1;
  visibility: visible;
}
.img-overlay-hover {
  overflow: hidden;
  position: relative;
}
.img-overlay-hover:before {
  opacity: 0;
  visibility: hidden;
  content: "";
  position: absolute;
  height: 100%;
  width: 50%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
  left: -50%;
  top: -100%;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.img-overlay-hover:after {
  opacity: 0;
  visibility: hidden;
  content: "";
  position: absolute;
  height: 100%;
  width: 50%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  right: -50%;
  top: -100%;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.img-overlay-hover:hover:before {
  left: 0;
  top: 0;
  opacity: 1;
  visibility: visible;
}
.img-overlay-hover:hover:after {
  right: 0;
  top: 0;
  opacity: 1;
  visibility: visible;
}
.hover-show-play-btn .play-btn {
  opacity: 0;
  visibility: hidden;
}
.hover-show-play-btn:hover .play-btn {
  opacity: 1;
  visibility: visible;
}
.react-tabs__tab--selected {
  background: #0089ff !important;

  /* border-color: #aaa; */
  color: white !important;

  border-radius: 5px 5px 0 0;
}
.img-scale-animate {
  overflow: hidden;
}
.img-scale-animate img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 1.5s ease-out;
  -moz-transition: all 1.5s ease-out;
  -ms-transition: all 1.5s ease-out;
  -o-transition: all 1.5s ease-out;
  transition: all 1.5s ease-out;
}
.img-scale-animate:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.img-grayscale-hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.img-grayscale-hover:hover img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.hover-color-dark:hover {
  color: #111 !important;
}
.img-overlay-90 {
  position: relative;
}
.img-overlay-90:before {
  opacity: 0.9;
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#111),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(transparent, #111);
  background-image: -moz-linear-gradient(transparent, #111),
    -moz-linear-gradient(transparent, #111);
  background-image: -o-linear-gradient(transparent, #111),
    -o-linear-gradient(transparent, #111);
  background-image: linear-gradient(transparent, #111),
    linear-gradient(transparent, #111);
  content: "";
  height: 90%;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.news_img{
  position: relative;
  height:496px;
}


.img-overlay-70 {
  position: relative;
}
.img-overlay-70:before {
  opacity: 0.7;
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#111),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(transparent, #111);
  background-image: -moz-linear-gradient(transparent, #111),
    -moz-linear-gradient(transparent, #111);
  background-image: -o-linear-gradient(transparent, #111),
    -o-linear-gradient(transparent, #111);
  background-image: linear-gradient(transparent, #111),
    linear-gradient(transparent, #111);
  content: "";
  height: 70%;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.more-info-link {
  position: absolute;
  z-index: 1;
  right: 0;
  top: -2px;
}
.more-info-link a {
  background: 0 0;
  color: #444;
  margin: 0;
  display: inline-block;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  padding: 5px 0 5px 10px;
}
.more-info-link a i {
  color: #dcdcdc;
  border: 1px solid #dcdcdc;
  margin-left: 15px;
  padding: 5px 10px;
  -webkit-transition: all 0.6s ease-out;
  -moz-transition: all 0.6s ease-out;
  -ms-transition: all 0.6s ease-out;
  -o-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
}
.more-info-link a:hover {
  color: #e53935;
}
.more-info-link a:hover i {
  color: #e53935;
  border: 1px solid #e53935;
}
.more-info-link-light {
  position: absolute;
  z-index: 1;
  right: 0;
  top: -2px;
}
.more-info-link-light a {
  background: 0 0;
  color: #fff;
  margin: 0;
  display: inline-block;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  padding: 5px 0 5px 10px;
}
.more-info-link-light a i {
  color: #fff;
  border: 1px solid #fff;
  margin-left: 15px;
  padding: 5px 10px;
  -webkit-transition: all 0.6s ease-out;
  -moz-transition: all 0.6s ease-out;
  -ms-transition: all 0.6s ease-out;
  -o-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
}
.more-info-link-light a:hover {
  color: #e53935;
}
.more-info-link-light a:hover i {
  color: #e53935;
  border: 1px solid #e53935;
}
.title-bar-left {
  position: relative;
  margin-bottom: 55px;
}
.title-bar-left:before {
  content: "";
  background-color: #e53935;
  height: 2px;
  width: 45px;
  position: absolute;
  bottom: -25px;
  left: 0;
  z-index: 5;
}
.divider {
  position: relative;
}
.divider:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 1px;
  height: 100px;
  background-color: #dcdcdc;
  z-index: 1;
  content: "";
  margin: 0 auto;
}
.ne-sidebar {
  margin-bottom: 30px;
}
.ne-sidebar .sidebar-box {
  margin-bottom: 5rem;
}
.ne-sidebar .sidebar-box:last-child {
  margin-bottom: 0 !important;
}
.item-box-light-lg {
  padding: 3rem 3.5rem;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 0 0 rgba(225, 225, 225, 0.75);
  -moz-box-shadow: 0 1px 0 0 rgba(225, 225, 225, 0.75);
  box-shadow: 0 1px 0 0 rgba(225, 225, 225, 0.75);
}
.item-box-light-md {
  padding: 3rem;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 0 0 rgba(225, 225, 225, 0.75);
  -moz-box-shadow: 0 1px 0 0 rgba(225, 225, 225, 0.75);
  box-shadow: 0 1px 0 0 rgba(225, 225, 225, 0.75);
}
.item-box-light-md-less20 {
  padding: 3rem 3rem 1rem;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 0 0 rgba(225, 225, 225, 0.75);
  -moz-box-shadow: 0 1px 0 0 rgba(225, 225, 225, 0.75);
  box-shadow: 0 1px 0 0 rgba(225, 225, 225, 0.75);
}
.item-box-light-md-less30 {
  display: inline-block;
  padding: 3rem 3rem 0;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 0 0 rgba(225, 225, 225, 0.75);
  -moz-box-shadow: 0 1px 0 0 rgba(225, 225, 225, 0.75);
  box-shadow: 0 1px 0 0 rgba(225, 225, 225, 0.75);
}
.item-box-light-md-less10 {
  padding: 3rem 3rem 2rem;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 0 0 rgba(225, 225, 225, 0.75);
  -moz-box-shadow: 0 1px 0 0 rgba(225, 225, 225, 0.75);
  box-shadow: 0 1px 0 0 rgba(225, 225, 225, 0.75);
}
.item-box-light-mix {
  padding: 3rem 3.5rem 1.5rem;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 0 0 rgba(225, 225, 225, 0.75);
  -moz-box-shadow: 0 1px 0 0 rgba(225, 225, 225, 0.75);
  box-shadow: 0 1px 0 0 rgba(225, 225, 225, 0.75);
}
.item-box-dark-md {
  padding: 3rem;
  background-color: #111;
  -webkit-box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.75);
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.75);
}
.item-box-dark-md-less10 {
  padding: 3rem 3rem 2rem;
  background-color: #111;
  -webkit-box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.75);
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.75);
}
.item-box-dark-md-less20 {
  padding: 3rem 3rem 1rem;
  background-color: #111;
  -webkit-box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.75);
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.75);
}
.item-box-dark-md-less30 {
  padding: 3rem 3rem 0;
  background-color: #111;
  -webkit-box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.75);
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.75);
}
.box-layout {
  padding-left: 30px;
  padding-right: 30px;
  border-right: 1px solid #d5d5d5;
  border-left: 1px solid #d5d5d5;
}
.stick .box-layout {
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
}
.img-overlay-70-c {
  position: relative;
}
.img-overlay-70-c:before {
  opacity: 0.7;
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#111),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(transparent, #111);
  background-image: -moz-linear-gradient(transparent, #111),
    -moz-linear-gradient(transparent, #111);
  background-image: -o-linear-gradient(transparent, #111),
    -o-linear-gradient(transparent, #111);
  background-image: linear-gradient(transparent, #111),
    linear-gradient(transparent, #111);
  content: "";
  height: 70%;
  bottom: -2px;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.item-shadow-gray {
  -webkit-box-shadow: 0 1px 1px 0 rgba(211, 211, 211, 0.75);
  -moz-box-shadow: 0 1px 1px 0 rgba(211, 211, 211, 0.75);
  box-shadow: 0 1px 1px 0 rgba(211, 211, 211, 0.75);
}
.item-shadow-1 {
  -webkit-box-shadow: 0 1px 0 0 rgba(211, 211, 211, 0.75);
  -moz-box-shadow: 0 1px 0 0 rgba(211, 211, 211, 0.75);
  box-shadow: 0 1px 0 0 rgba(211, 211, 211, 0.75);
}
.topic-border {
  position: relative;
}
.topic-border:after {
  position: absolute;
  display: block;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  height: 2px;
}
.topic-box {
  display: inline-block;
  position: relative;
  background-color: #e53935;
  color: #fff;
  margin-left: -13px;
  height: 40px;
  padding: 0 20px;
  line-height: 42px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
}
.topic-box:before {
  z-index: 1;
  content: "";
  top: 0;
  right: -8px;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 8px solid #e53935;
  border-right: 8px solid transparent;
}
.topic-box-lg {
  display: inline-block;
  position: relative;
  color: #fff;
  height: 35px;
  padding: 0 15px;
  line-height: 35px;
  text-align: center;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 15px;
  font-family: Poppins, sans-serif;
  z-index: 2;
}
.topic-box-lg:before {
  z-index: 1;
  content: "";
  top: 0;
  right: -8px;
  position: absolute;
  width: 0;
  height: 0;
}
.topic-box-lg:after {
  content: "";
  height: 100%;
  width: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.topic-box-sm {
  display: inline-block;
  position: relative;
  color: #fff;
  height: 26px;
  padding: 0 15px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  z-index: 2;
}
.topic-box-sm:before {
  z-index: 1;
  content: "";
  top: 0;
  right: -8px;
  position: absolute;
  width: 0;
  height: 0;
}
.topic-box-sm:after {
  content: "";
  height: 100%;
  width: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.color-cinnabar:before {
  border-top: 8px solid #e53935;
  border-right: 8px solid transparent;
}
.color-cinnabar:after {
  background-color: #e53935;
}
.color-cinnabar .owl-controls .owl-nav,
.color-cinnabar .owl-controls .owl-nav > div {
  background-color: transparent;
}
.color-cinnabar .owl-controls .owl-nav i {
  color: #111;
}
.color-cinnabar .owl-controls .owl-prev:hover {
  border: 1px solid #e53935;
  background: #e53935;
}
.color-cinnabar .owl-controls .owl-prev:hover i {
  color: #fff;
}
.color-cinnabar .owl-controls .owl-next:hover {
  border: 1px solid #e53935;
  background: #e53935;
}
.color-cinnabar .owl-controls .owl-next:hover i {
  color: #fff;
}
.color-cinnabar-transparent:before {
  border-top: 8px solid #e53935;
  border-right: 8px solid transparent;
}
.color-cinnabar-transparent:after {
  background-color: #e53935;
}
.color-cinnabar-transparent .owl-controls .owl-nav,
.color-cinnabar-transparent .owl-controls .owl-nav > div {
  background-color: transparent;
}
.color-cinnabar-transparent .owl-controls .owl-nav i {
  color: #fff;
}
.color-cinnabar-transparent .owl-controls .owl-prev:hover {
  border: 1px solid #e53935;
  background: #e53935;
}
.color-cinnabar-transparent .owl-controls .owl-prev:hover i {
  color: #fff;
}
.color-cinnabar-transparent .owl-controls .owl-next:hover {
  border: 1px solid #e53935;
  background: #e53935;
}
.color-cinnabar-transparent .owl-controls .owl-next:hover i {
  color: #fff;
}
.color-azure-radiance:before {
  border-top: 8px solid #0089ff;
  border-right: 8px solid transparent;
}
.color-azure-radiance:after {
  background-color: #0089ff;
}
.color-azure-radiance .owl-controls i {
  color: #111;
}
.color-azure-radiance .owl-controls .owl-nav {
  background-color: transparent;
}
.color-azure-radiance .owl-controls .owl-prev:hover {
  border: 1px solid #0089ff;
  background: #0089ff;
}
.color-azure-radiance .owl-controls .owl-prev:hover i {
  color: #fff;
}
.color-azure-radiance .owl-controls .owl-next:hover {
  border: 1px solid #0089ff;
  background: #0089ff;
}
.color-azure-radiance .owl-controls .owl-next:hover i {
  color: #fff;
}
.color-ecstasy:before {
  border-top: 8px solid #f57f17;
  border-right: 8px solid transparent;
}
.color-ecstasy:after {
  background-color: #f57f17;
}
.color-ecstasy .owl-controls i {
  color: #111;
}
.color-ecstasy .owl-controls .owl-nav {
  background-color: transparent;
}
.color-ecstasy .owl-controls .owl-prev:hover {
  border: 1px solid #f57f17;
  background: #f57f17;
}
.color-ecstasy .owl-controls .owl-prev:hover i {
  color: #fff;
}
.color-ecstasy .owl-controls .owl-next:hover {
  border: 1px solid #f57f17;
  background: #f57f17;
}
.color-ecstasy .owl-controls .owl-next:hover i {
  color: #fff;
}
.color-apple:before {
  border-top: 8px solid #43a047;
  border-right: 8px solid transparent;
}
.color-apple:after {
  background-color: #43a047;
}
.color-apple .owl-controls i {
  color: #111;
}
.color-apple .owl-controls .owl-nav {
  background-color: transparent;
}
.color-apple .owl-controls .owl-prev:hover {
  border: 1px solid #43a047;
  background: #43a047;
}
.color-apple .owl-controls .owl-prev:hover i {
  color: #fff;
}
.color-apple .owl-controls .owl-next:hover {
  border: 1px solid #43a047;
  background: #43a047;
}
.color-apple .owl-controls .owl-next:hover i {
  color: #fff;
}
.color-cod-gray:before {
  border-top: 8px solid #111;
  border-right: 8px solid transparent;
}
.color-cod-gray:after {
  background-color: #111;
}
.color-cod-gray .owl-controls i {
  color: #111;
}
.color-cod-gray .owl-controls .owl-nav {
  background-color: transparent;
}
.color-cod-gray .owl-controls .owl-prev:hover {
  border: 1px solid #111;
  background: #111;
}
.color-cod-gray .owl-controls .owl-prev:hover i {
  color: #fff;
}
.color-cod-gray .owl-controls .owl-next:hover {
  border: 1px solid #111;
  background: #111;
}
.color-cod-gray .owl-controls .owl-next:hover i {
  color: #fff;
}
.color-razzmatazz:before {
  border-top: 8px solid #ed145b;
  border-right: 8px solid transparent;
}
.color-razzmatazz:after {
  background-color: #ed145b;
}
.color-razzmatazz .owl-controls i {
  color: #111;
}
.color-razzmatazz .owl-controls .owl-nav {
  background-color: transparent;
}
.color-razzmatazz .owl-controls .owl-prev:hover {
  border: 1px solid #ed145b;
  background: #ed145b;
}
.color-razzmatazz .owl-controls .owl-prev:hover i {
  color: #fff;
}
.color-razzmatazz .owl-controls .owl-next:hover {
  border: 1px solid #ed145b;
  background: #ed145b;
}
.color-razzmatazz .owl-controls .owl-next:hover i {
  color: #fff;
}
.color-pomegranate:before {
  border-top: 8px solid #f4511e;
  border-right: 8px solid transparent;
}
.color-pomegranate:after {
  background-color: #f4511e;
}
.color-pomegranate .owl-controls i {
  color: #111;
}
.color-pomegranate .owl-controls .owl-nav {
  background-color: transparent;
}
.color-pomegranate .owl-controls .owl-prev:hover {
  border: 1px solid #f4511e;
  background: #f4511e;
}
.color-pomegranate .owl-controls .owl-prev:hover i {
  color: #fff;
}
.color-pomegranate .owl-controls .owl-next:hover {
  border: 1px solid #f4511e;
  background: #f4511e;
}
.color-pomegranate .owl-controls .owl-next:hover i {
  color: #fff;
}
.color-cutty-sark:before {
  border-top: 8px solid #546e7a;
  border-right: 8px solid transparent;
}
.color-cutty-sark:after {
  background-color: #546e7a;
}
.color-cutty-sark .owl-controls i {
  color: #111;
}
.color-cutty-sark .owl-controls .owl-nav {
  background-color: transparent;
}
.color-cutty-sark .owl-controls .owl-prev:hover {
  border: 1px solid #546e7a;
  background: #546e7a;
}
.color-cutty-sark .owl-controls .owl-prev:hover i {
  color: #fff;
}
.color-cutty-sark .owl-controls .owl-next:hover {
  border: 1px solid #546e7a;
  background: #546e7a;
}
.color-cutty-sark .owl-controls .owl-next:hover i {
  color: #fff;
}
.color-persian-green:before {
  border-top: 8px solid #009688;
  border-right: 8px solid transparent;
}
.color-persian-green:after {
  background-color: #009688;
}
.color-persian-green .owl-controls i {
  color: #111;
}
.color-persian-green .owl-controls .owl-nav {
  background-color: transparent;
}
.color-persian-green .owl-controls .owl-prev:hover {
  border: 1px solid #009688;
  background: #009688;
}
.color-persian-green .owl-controls .owl-prev:hover i {
  color: #fff;
}
.color-persian-green .owl-controls .owl-next:hover {
  border: 1px solid #009688;
  background: #009688;
}
.color-persian-green .owl-controls .owl-next:hover i {
  color: #fff;
}
.color-web-orange:before {
  border-top: 8px solid #ffab00;
  border-right: 8px solid transparent;
}
.color-web-orange:after {
  background-color: #ffab00;
}
.color-web-orange .owl-controls i {
  color: #111;
}
.color-web-orange .owl-controls .owl-nav {
  background-color: transparent;
}
.color-web-orange .owl-controls .owl-prev:hover {
  border: 1px solid #ffab00;
  background: #ffab00;
}
.color-web-orange .owl-controls .owl-prev:hover i {
  color: #fff;
}
.color-web-orange .owl-controls .owl-next:hover {
  border: 1px solid #ffab00;
  background: #ffab00;
}
.color-web-orange .owl-controls .owl-next:hover i {
  color: #fff;
}
.color-scampi:before {
  border-top: 8px solid #605ca8;
  border-right: 8px solid transparent;
}
.color-scampi:after {
  background-color: #605ca8;
}
.color-scampi .owl-controls i {
  color: #111;
}
.color-scampi .owl-controls .owl-nav {
  background-color: transparent;
}
.color-scampi .owl-controls .owl-prev:hover {
  border: 1px solid #605ca8;
  background: #605ca8;
}
.color-scampi .owl-controls .owl-prev:hover i {
  color: #fff;
}
.color-scampi .owl-controls .owl-next:hover {
  border: 1px solid #605ca8;
  background: #605ca8;
}
.color-scampi .owl-controls .owl-next:hover i {
  color: #fff;
}
.color-hollywood-cerise:before {
  border-top: 8px solid #ec008c;
  border-right: 8px solid transparent;
}
.color-hollywood-cerise:after {
  background-color: #ec008c;
}
.color-hollywood-cerise .owl-controls i {
  color: #111;
}
.color-hollywood-cerise .owl-controls .owl-nav {
  background-color: transparent;
}
.color-hollywood-cerise .owl-controls .owl-prev:hover {
  border: 1px solid #ec008c;
  background: #ec008c;
}
.color-hollywood-cerise .owl-controls .owl-prev:hover i {
  color: #fff;
}
.color-hollywood-cerise .owl-controls .owl-next:hover {
  border: 1px solid #ec008c;
  background: #ec008c;
}
.color-hollywood-cerise .owl-controls .owl-next:hover i {
  color: #fff;
}
.color-white {
  color: #111;
}
.color-white:before {
  border-top: 8px solid #fff;
  border-right: 8px solid transparent;
}
.color-white:after {
  background-color: #fff;
}
.color-white .owl-controls i {
  color: #111;
}
.color-white .owl-controls .owl-nav {
  background-color: transparent;
}
.color-white .owl-controls .owl-prev:hover {
  border: 1px solid #fff;
  background: #fff;
}
.color-white .owl-controls .owl-prev:hover i {
  color: #fff;
}
.color-white .owl-controls .owl-next:hover {
  border: 1px solid #fff;
  background: #fff;
}
.color-white .owl-controls .owl-next:hover i {
  color: #fff;
}
.color-white2 {
  color: #111;
}
.color-white2:before {
  border-top: 8px solid #fff;
  border-right: 8px solid transparent;
}
.color-white2:after {
  background-color: #fff;
}
.color-white2 .owl-controls i {
  color: #fff;
}
.color-white2 .owl-controls .owl-nav {
  background-color: transparent;
}
.color-white2 .owl-controls .owl-prev:hover {
  border: 1px solid #fff;
  background: #fff;
}
.color-white2 .owl-controls .owl-prev:hover i {
  color: #111;
}
.color-white2 .owl-controls .owl-next:hover {
  border: 1px solid #fff;
  background: #fff;
}
.color-white2 .owl-controls .owl-next:hover i {
  color: #111;
}
.topic-box-top-lg {
  position: absolute;
  top: 30px;
  left: 30px;
}
.topic-box-top-sm {
  position: absolute;
  top: 20px;
  left: 20px;
}
.topic-box-top-xs {
  position: absolute;
  top: 10px;
  left: 10px;
}
.post-date-light ul {
  line-height: 1;
}
.post-date-light ul li {
  display: inline-block;
  line-height: 1;
  margin-bottom: 12px;
  font-size: 14px;
  color: #fff;
  margin-right: 15px;
}
.post-date-light ul li:last-child {
  margin-right: 0;
}
.post-date-light ul li a {
  color: #fff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.post-date-light ul li a:hover {
  color: #e53935;
}
.post-date-light ul li span {
  float: left;
  font-weight: 700;
  margin-right: 8px;
  display: inline-block;
}
.post-date-dark ul {
  line-height: 1;
}
.post-date-dark ul li {
  display: inline-block;
  line-height: 1;
  margin-bottom: 12px;
  font-size: 14px;
  color: #111;
  margin-right: 15px;
}
.post-date-dark ul li:last-child {
  margin-right: 0;
}
.post-date-dark ul li a {
  color: #111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.post-date-dark ul li a:hover {
  color: #e53935;
}
.post-date-dark ul li span {
  float: left;
  font-weight: 700;
  margin-right: 8px;
  display: inline-block;
}
.mask-content-v-center {
  position: absolute;
  z-index: 7;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
  left: 30px;
  padding-right: 30px;
}
.mask-content-lg {
  position: absolute;
  z-index: 7;
  bottom: 10px;
  left: 30px;
  text-align: left;
  padding-right: 30px;
}
@media only screen and (max-width: 575px) {
  .mask-content-lg {
    left: 15px;
    bottom: 0;
  }
}
.mask-content-top-left {
  position: absolute;
  z-index: 7;
  top: 30px;
  left: 30px;
  padding-right: 30px;
}
.mask-content-sm {
  position: absolute;
  z-index: 7;
  bottom: 5px;
  left: 25px;
  padding-right: 25px;
  text-align: left;
  color: white;
  font-size: large;
}
.mask-content-xs {
  position: absolute;
  z-index: 7;
  bottom: 0;
  left: 20px;
  padding-right: 20px;
}
.section-space-default {
  padding: 50px 0;
}
@media only screen and (max-width: 767px) {
  .section-space-default {
    padding: 40px 0;
  }
}
@media only screen and (max-width: 575px) {
  .section-space-default {
    padding: 30px 0;
  }
}
.section-space-less10 {
  padding: 50px 0 40px;
}
@media only screen and (max-width: 767px) {
  .section-space-less10 {
    padding: 40px 0 30px;
  }
}
@media only screen and (max-width: 575px) {
  .section-space-less10 {
    padding: 30px 0 20px;
  }
}
.section-space-less4 {
  padding: 50px 0 46px;
  
}
@media only screen and (max-width: 767px) {
  .section-space-less4 {
    padding: 40px 0 36px;
  }
}
@media only screen and (max-width: 575px) {
  .section-space-less4 {
    padding: 30px 0 26px;
  }
}
.section-space-bottom {
  padding: 0 0 50px;
}
@media only screen and (max-width: 767px) {
  .section-space-bottom {
    padding: 0 0 40px;
  }
}
@media only screen and (max-width: 575px) {
  .section-space-bottom {
    padding: 0 0 30px;
  }
}
.section-space-bottom-less4 {
  padding: 0 0 46px;
}
@media only screen and (max-width: 767px) {
  .section-space-bottom-less4 {
    padding: 0 0 36px;
  }
}
@media only screen and (max-width: 575px) {
  .section-space-bottom-less4 {
    padding: 0 0 26px;
  }
}
.section-space-bottom-less30 {
  padding: 0 0 20px;
}
@media only screen and (max-width: 767px) {
  .section-space-bottom-less30 {
    padding: 0 0 10px;
  }
}
@media only screen and (max-width: 575px) {
  .section-space-bottom-less30 {
    padding: 0;
  }
}
.section-space-less2 {
  padding: 50px 0 48px;
}
@media only screen and (max-width: 767px) {
  .section-space-less2  {
    padding: 40px 0 38px;
  }
}
@media only screen and (max-width: 575px) {
  .section-space-less2 {
    padding: 30px 0 28px;
  }
}
.section-space-less30 {
  padding: 50px 0 20px;
}
@media only screen and (max-width: 767px) {
  .section-space-less30 {
    padding: 40px 0 10px;
  }
}
@media only screen and (max-width: 575px) {
  .section-space-less30 {
    padding: 30px 0 0;
  }
}
.bg-dark-layout {
  position: relative;
  background-color: #27292d;
  background-repeat: no-repeat;
  background-position: top;
}
.ne-banner-layout1 {
  line-height: 0;
}
.ne-banner-layout1 a {
  display: inline-block;
}
.ne-banner-layout2 {
  line-height: 0;
}
.ne-banner-layout2 a {
  display: block;
}
.play-btn {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  display: block;
  margin: 0 auto;
  z-index: 9;
  width: 60px;
  height: 60px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.play-btn img {
  margin: 0 auto;
  opacity: 0.7;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.play-btn:hover img {
  opacity: 1;
}
.isotop-btn {
  position: absolute;
  z-index: 1;
  right: 0px;
  top: -2px;
  font-size: medium;
}
@media only screen and (max-width: 767px) {
  .isotop-btn {
    position: relative;
    top: 0;
    left: -10px;
    padding: 10px 0;
  }
}
.isotop-btn:after {
  display: none;
}
.isotop-btn a {
  background: 0 0;
  color: #444;
  padding: 5px 8px;
  margin: 0;
  display: inline-block;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.isotop-btn a:hover {
  color: #e53935;
}
.isotop-btn a:hover i {
  color: #e53935;
  border: 1px solid #e53935;
}
.isotop-btn .current {
  color: #e53935;
}
.isotop-btn-light {
  position: absolute;
  z-index: 1;
  right: 100px;
  top: -2px;
}
@media only screen and (max-width: 767px) {
  .isotop-btn-light {
    position: relative;
    top: 0;
    left: -10px;
    padding: 10px 0;
  }
}
.isotop-btn-light:after {
  display: none;
}
.isotop-btn-light a {
  background: 0 0;
  color: #fff;
  padding: 5px 8px;
  margin: 0;
  display: inline-block;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.isotop-btn-light a:hover {
  color: #e53935;
}
.isotop-btn-light a:hover i {
  color: #e53935;
  border: 1px solid #e53935;
}
.isotop-btn-light .current {
  color: #e53935;
}
.btn-tab {
  border-bottom: 0;
  margin-bottom: 30px;
}
.btn-tab li {
  margin-bottom: 0 !important;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
}
.btn-tab li a {
  background-color: #f8f8f8;
  border: 1px solid #dcdcdc;
  padding: 10px 15px;
  display: block;
  color: #111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-tab li a:hover {
  border: 1px solid #e53935;
  background-color: #e53935;
  color: #fff;
}
.btn-tab .active {
  border: 1px solid #e53935 !important;
  background-color: #e53935 !important;
  color: #fff !important;
}
.btn-ftg-ptp-56 {
  display: inline-block;
  height: 5.6rem;
  line-height: 5.2rem;
  padding: 0 4rem;
  border: 2px solid #e53935;
  background-color: #e53935;
  text-transform: uppercase;
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 700;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-ftg-ptp-56:hover {
  background-color: transparent;
  color: #e53935;
}
.btn-ftg-ptp-56:focus {
  outline: 0;
}
.btn-ftg-ptp-45 {
  display: inline-block;
  height: 4.5rem;
  line-height: 4.1rem;
  padding: 0 2.5rem;
  border: 2px solid #e53935;
  background-color: #e53935;
  text-transform: uppercase;
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 700;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-ftg-ptp-45:hover {
  background-color: transparent;
  color: #e53935;
}
.btn-ftg-ptp-45:focus {
  outline: 0;
}
.btn-ftg-ptp-40 {
  display: inline-block;
  height: 40px;
  line-height: 36px;
  width: 100%;
  border: 2px solid #e53935;
  background-color: #e53935;
  text-transform: capitalize;
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-ftg-ptp-40:hover {
  background-color: transparent;
  color: #e53935;
}
.btn-ftg-ptp-40:focus {
  outline: 0;
}
.btn-gtf-ltl-64 {
  display: inline-block;
  height: 6.4rem;
  line-height: 6rem;
  padding: 0 4rem;
  border: 2px solid #fff;
  background-color: transparent;
  text-transform: uppercase;
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 700;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  -ms-border-radius: 45px;
  -o-border-radius: 45px;
  border-radius: 45px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-gtf-ltl-64:hover {
  background-color: #fff;
  color: #e53935;
}
.btn-gtf-ltl-64:focus {
  outline: 0;
}
.btn-gtf-dtp-50 {
  display: inline-block;
  height: 5rem;
  line-height: 4.8rem;
  padding: 0 3rem;
  border: 1px solid #dcdcdc;
  background-color: transparent;
  text-transform: capitalize;
  color: #111;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-gtf-dtp-50 i {
  color: #e53935;
  margin-right: 12px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-gtf-dtp-50:hover {
  border: 1px solid #e53935;
  background-color: #e53935;
  color: #fff !important;
}
.btn-gtf-dtp-50:hover i {
  color: #fff;
}
.btn-gtf-dtp-50:focus {
  outline: 0;
  color: #111;
}
.btn-ftf-dtp-52 {
  display: inline-block;
  height: 5.2rem;
  line-height: 5.2rem;
  padding: 0 4rem;
  background-color: #111;
  text-transform: capitalize;
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 700;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-ftf-dtp-52:hover {
  background-color: #e53935;
  color: #fff;
}
.btn-ftf-dtp-52:focus {
  outline: 0;
}
@media only screen and (min-width: 576px) {
  .nav-control-top2 {
    position: relative;
  }
  .nav-control-top2 .owl-controls {
    position: absolute;
    top: -65px;
    right: -2px;
    z-index: 5;
  }
  .nav-control-top2 .owl-controls i {
    display: block;
    line-height: 25px;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  .nav-control-top2 .owl-controls .owl-nav {
    margin-top: 0;
  }
  .nav-control-top2 .owl-controls .owl-nav > div {
    padding: 0;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    background-color: transparent;
    opacity: 1;
    font-size: 14px;
    margin: 0 2px;
    height: 25px;
    width: 25px;
    border: 1px solid #dcdcdc;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
}
@media only screen and (max-width: 575px) {
  .nav-control-top2 {
    position: relative;
  }
  .nav-control-top2 .owl-controls i {
    color: #e53935;
    display: block;
    line-height: 15px;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  .nav-control-top2 .owl-controls .owl-nav {
    margin-top: 0;
  }
  .nav-control-top2 .owl-controls .owl-nav > div {
    position: absolute;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 50%;
    background: 0 0;
    border: 1px solid #e53935;
    opacity: 1;
    font-size: 14px;
    margin: 0 2px;
    height: 25px;
    width: 25px;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  .nav-control-top2 .owl-controls .owl-prev {
    left: -15px;
  }
  .nav-control-top2 .owl-controls .owl-prev:hover {
    background: #e53935;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  .nav-control-top2 .owl-controls .owl-prev:hover i {
    color: #fff;
  }
  .nav-control-top2 .owl-controls .owl-next {
    right: -15px;
  }
  .nav-control-top2 .owl-controls .owl-next:hover {
    background: #e53935;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  .nav-control-top2 .owl-controls .owl-next:hover i {
    color: #fff;
  }
}
ul.news-info-list {
  padding: 6px 0;
}
ul.news-info-list li {
  font-size: 13px;
  margin-right: 30px;
  display: inline-block;
  color: #444;
}
@media only screen and (max-width: 1199px) {
  ul.news-info-list li {
    margin-right: 12px;
  }
}
ul.news-info-list li:last-child {
  margin-right: 0;
}
ul.news-info-list li i {
  color: #e53935;
  font-size: 16px;
  margin-right: 15px;
}
ul.header-social {
  text-align: right;
  padding: 4px 0;
  font-size: medium;
}
ul.header-social li {
  display: inline-block;
  margin-right: 5px;
}
ul.header-social li:last-child {
  margin-right: 0;
}
ul.header-social li a {
  height: 30px;
  width: 30px;
  line-height: 30px;
  display: block;
  text-align: center;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  -ms-border-radius: 45px;
  -o-border-radius: 45px;
  border-radius: 45px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.header-action-item {
  text-align: right;
}
@media only screen and (max-width: 991px) {
  .header-action-item {
    position: absolute;
    right: 0;
    top: 17px;
    z-index: 100;
  }
}
@media only screen and (max-width: 991px) {
  .header-action-item ul li:nth-child(n + 3) {
    display: none;
  }
  .header-action-item ul li:nth-child(2n) {
    border-right: 0;
    margin-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .header-action-item ul li:nth-child(n + 2) {
    display: none;
  }
  .header-action-item ul li:nth-child(1n) {
    border-right: 0;
    margin-right: 0;
  }
}
.header-action-item ul li {
  border-right: 1px solid #dcdcdc;
  line-height: 18px;
  padding-right: 15px;
  display: inline-block;
  margin-right: 15px;
}
.header-action-item ul li:last-child {
  border-right: 0;
  padding-right: 0;
  margin-right: 0;
}
.login-btn {
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: 14px;
  display: block;
  color: black;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.login-btn i {
  padding-right: 10px;
  font-size: 14px;
  color: #e53935;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.login-btn:hover {
  color: #e53935;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}


.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
 
}


.mb-input-search .form-control{
  font-size: 14px;
  height: 23px !important;
  width: 50% !important;
  
}


.login-btn {
 
  border: none;
  background-color: transparent;
  font-size: 14px;
  display: block;
  color: black;
  transition: all 0.3s ease-out;
  height :20px;
}
.login-btn:focus {
  outline: 0;
}
.header-search-light {
  position: relative;
  line-height: 0;
}
.header-search-light button {
  background-color: transparent;
  border: 0;
  padding: 0;
}
.header-search-light button:focus {
  outline: 0;
}
.header-search-light .search-input {
  position: absolute;
  top: -13px;
  right: 33px;
  z-index: 10;
  height: 42px;
  width: 800px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.98);
  border: none;
  padding: 5px 30px 8px;
  color: #111;
  display: none;
  -webkit-box-shadow: 0 3px 10px 2px rgba(229, 57, 53, 0.3);
  -moz-box-shadow: 0 3px 10px 2px rgba(229, 57, 53, 0.3);
  box-shadow: 0 3px 10px 2px rgba(229, 57, 53, 0.3);
}
@media only screen and (max-width: 1199px) {
  .header-search-light .search-input {
    right: 25px;
    width: 740px;
  }
}
@media only screen and (max-width: 991px) {
  .header-search-light .search-input {
    right: 25px;
    width: 560px;
  }
}
@media only screen and (max-width: 767px) {
  .header-search-light .search-input {
    right: 25px;
    width: 230px;
  }
}
.header-search-light input.search-input:focus {
  outline: 0;
}
.header-search-light input::-webkit-input-placeholder,
.header-search-light textarea::-webkit-input-placeholder {
  color: #111;
}
.header-search-light input::-moz-placeholder,
.header-search-light textarea::-moz-placeholder {
  color: #111;
}
.header-search-light input:-moz-placeholder,
.header-search-light textarea:-moz-placeholder {
  color: #111;
}
.header-search-light input:-ms-input-placeholder,
.header-search-light textarea:-ms-input-placeholder {
  color: #111;
}
.header-search-light i {
  color: #fff;
}
.header-search-dark {
  position: relative;
  line-height: 0;
}
.header-search-dark button {
  background-color: transparent;
  border: 0;
  padding: 0;
}
.header-search-dark button:focus {
  outline: 0;
}
.header-search-dark .search-input {
  position: absolute;
  top: -13px;
  right: 33px;
  z-index: 10;
  height: 42px;
  width: 960px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.98);
  border: none;
  padding: 5px 30px 8px;
  color: #111;
  display: none;
  -webkit-box-shadow: 0 3px 10px 2px rgba(229, 57, 53, 0.3);
  -moz-box-shadow: 0 3px 10px 2px rgba(229, 57, 53, 0.3);
  box-shadow: 0 3px 10px 2px rgba(229, 57, 53, 0.3);
}
@media only screen and (max-width: 1199px) {
  .header-search-dark .search-input {
    right: 25px;
    width: 730px;
  }
}
@media only screen and (max-width: 991px) {
  .header-search-dark .search-input {
    right: 25px;
    width: 560px;
  }
}
@media only screen and (max-width: 767px) {
  .header-search-dark .search-input {
    right: 25px;
    width: 230px;
  }
}
.header-search-dark input.search-input:focus {
  outline: 0;
}
.header-search-dark input::-webkit-input-placeholder,
.header-search-dark textarea::-webkit-input-placeholder {
  color: #111;
}
.header-search-dark input::-moz-placeholder,
.header-search-dark textarea::-moz-placeholder {
  color: #111;
}
.header-search-dark input:-moz-placeholder,
.header-search-dark textarea:-moz-placeholder {
  color: #111;
}
.header-search-dark input:-ms-input-placeholder,
.header-search-dark textarea:-ms-input-placeholder {
  color: #111;
}
.header-search-dark i {
  color: #111;
}
@media only screen and (max-width: 991px) {
  .header-search-dark i {
    color: #fff;
  }
}
.offcanvas-menu-btn {
  position: relative;
  width: 12px;
  margin-left: 15px;
}
.offcanvas-menu-btn a.menu-bar {
  height: 30px;
  position: absolute;
  top: -20px;
  display: block;
  left: -15px;
}
.offcanvas-menu-btn a.menu-bar span {
  display: block;
  height: 1px;
  width: 28px;
  background-color: #fff;
  margin-top: 7px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.offcanvas-menu-btn a.menu-times {
  height: 30px;
  position: absolute;
  top: -20px;
  display: block;
  left: -15px;
}
.offcanvas-menu-btn a.menu-times span {
  display: block;
  height: 1px;
  width: 28px;
  background-color: #fff;
  margin-top: 7px;
}
.offcanvas-menu-btn a.menu-times span:first-child {
  position: relative;
  bottom: -8px;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.offcanvas-menu-btn a.menu-times span:last-child {
  position: relative;
  bottom: 0;
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.offcanvas-menu-btn a.menu-bar.close,
.offcanvas-menu-btn a.menu-bar.open,
.offcanvas-menu-btn a.menu-times.close {
  display: none;
}
.offcanvas-menu-btn a.menu-times.open {
  display: block;
}
.offcanvas-menu-btn a.menu-times.re-point {
  top: -20px;
  left: 250px;
}
.offcanvas-btn-repoint {
  margin-left: 30px;
  width: inherit;
}
.offcanvas-body-wrapper {
  height: 100%;
  width: 300px;
  position: fixed;
  z-index: 999999;
  top: 0;
  right: -300px;
  background-color: #2c2c2c;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  overflow-x: hidden;
  padding-top: 30px;
  -webkit-box-shadow: inset 4px 0 15px 2px rgba(255, 255, 255, 0.3);
  -moz-box-shadow: inset 4px 0 15px 2px rgba(255, 255, 255, 0.3);
  box-shadow: inset 4px 0 15px 2px rgba(255, 255, 255, 0.3);
}
.offcanvas-body-wrapper .offcanvas-main-body ul.offcanvas-nav {
  margin-top: 40px;
}
.offcanvas-body-wrapper .offcanvas-main-body ul.offcanvas-nav > li {
  display: block;
  border-bottom: 1px solid #000;
}
.offcanvas-body-wrapper .offcanvas-main-body ul.offcanvas-nav > li:last-child {
  border-bottom: 0;
}
.offcanvas-body-wrapper .offcanvas-main-body ul.offcanvas-nav > li > a {
  position: relative;
  width: 100%;
  display: inline-block;
  padding: 12px 0 12px 70px;
  color: #fff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.offcanvas-body-wrapper .offcanvas-main-body ul.offcanvas-nav > li > a i {
  position: absolute;
  top: 12px;
  left: 30px;
  font-size: 20px;
  margin-right: 20px;
  color: #818181;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.offcanvas-body-wrapper .offcanvas-main-body ul.offcanvas-nav > li > a:hover {
  background-color: #e53935;
}
.offcanvas-body-wrapper .offcanvas-main-body ul.offcanvas-nav > li > a:hover i {
  color: #fff;
}
.offcanvas-body-wrapper
  .offcanvas-main-body
  ul.offcanvas-nav
  > li
  ul.offcanvas-sub-nav {
  background-color: #393939;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.offcanvas-body-wrapper
  .offcanvas-main-body
  ul.offcanvas-nav
  > li
  ul.offcanvas-sub-nav
  li
  a {
  position: relative;
  border-bottom: 1px solid #303030;
  display: inline-block;
  width: 100%;
  padding: 10px 0 10px 100px;
  color: #fff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.offcanvas-body-wrapper
  .offcanvas-main-body
  ul.offcanvas-nav
  > li
  ul.offcanvas-sub-nav
  li
  a:hover {
  color: #e53935;
  background-color: #303030;
}
.offcanvas-body-wrapper
  .offcanvas-main-body
  ul.offcanvas-nav
  > li
  ul.offcanvas-sub-nav
  li
  a:hover:before {
  opacity: 1;
  visibility: visible;
}
.offcanvas-body-wrapper
  .offcanvas-main-body
  ul.offcanvas-nav
  > li
  ul.offcanvas-sub-nav
  li
  a:before {
  opacity: 0;
  visibility: hidden;
  height: 100%;
  width: 3px;
  content: "";
  background-color: #e53935;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.offcanvas-body-wrapper
  .offcanvas-main-body
  ul.offcanvas-nav
  > li
  ul.offcanvas-sub-nav
  li:last-child
  a {
  border-bottom: 0;
}
.offcanvas-body-wrapper
  .offcanvas-main-body
  ul.offcanvas-nav
  .panel-default
  > .panel-heading {
  background-color: transparent;
  padding: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.offcanvas-body-wrapper
  .offcanvas-main-body
  ul.offcanvas-nav
  .panel-default
  > .panel-heading
  a {
  position: relative;
  width: 100%;
  display: inline-block;
  padding: 12px 0 12px 70px;
  color: #fff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.offcanvas-body-wrapper
  .offcanvas-main-body
  ul.offcanvas-nav
  .panel-default
  > .panel-heading
  a
  i {
  position: absolute;
  top: 15px;
  left: 30px;
  font-size: 20px;
  margin-right: 20px;
  color: #818181;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.offcanvas-body-wrapper
  .offcanvas-main-body
  ul.offcanvas-nav
  .panel-default
  > .panel-heading
  a:hover {
  background-color: #e53935;
}
.offcanvas-body-wrapper
  .offcanvas-main-body
  ul.offcanvas-nav
  .panel-default
  > .panel-heading
  a:hover
  i {
  color: #fff;
}
.offcanvas-body-wrapper
  .offcanvas-main-body
  ul.offcanvas-nav
  .panel-heading.active
  a {
  background-color: #e53935;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.offcanvas-body-wrapper
  .offcanvas-main-body
  ul.offcanvas-nav
  .panel-heading.active
  a
  i {
  color: #fff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.offcanvas-body-wrapper .offcanvas-main-body ul.offcanvas-nav .panel {
  border: 0;
  border-bottom: 1px solid #000;
  border-radius: 0;
  background-color: transparent;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.offcanvas-body-wrapper
  .offcanvas-main-body
  ul.offcanvas-nav
  .panel
  .panel-body {
  border-top: 0;
  padding: 0;
}
.offcanvas-body-wrapper .offcanvas-main-body .panel-group .panel + .panel {
  margin-top: 0;
}
div#wrapper.open > .offcanvas-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 20;
  content: "";
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.title-login-form {
  font-size: 30px;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 600;
  color: #e53935;
  margin-left: auto;
  margin-right: auto;
}

.forgot-btn{
  font-size: 30px;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 600;
  color: #e53935;
  margin-left: auto;
  margin-right: auto;
}

.title-register-form {
  font-size: 30px;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 600;
  color: #e53935;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .title-login-form {
    font-size: 28px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .title-login-form {
    font-size: 26px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .title-login-form {
    font-size: 24px;
  }
}
@media only screen and (max-width: 575px) {
  .title-login-form {
    font-size: 22px;
  }
}
.title-login-form a {
  color: #e53935;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.title-login-form a:hover {
  color: #ff0;
}
.login-form {
  background: #fff;
  border: 1px solid #dcdcdc;
  padding: 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
@media only screen and (max-width: 767px) {
  .login-form {
    margin-bottom: 15px;
  }
}
.login-form label {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #111;
}

.login-form input {
  height: 40px;
  padding: 0 10px;
  width: 100%;
  border-radius: 0;
  border: 1px solid #dcdcdc;
  /* margin-bottom: 15px; */
  color: #111;
  font-weight: 500;
  font-size: 15px;
}

.login-form input:focus {
  outline: 0;
  border: 1px solid #dcdcdc;
}
.login-form button {
  /* margin-bottom: 30px; */
  margin: 0px 25px 8px 0px;
  color: #fff;
  padding: 5px 0;
  background: #e53935;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 400;
  display: inline-block;
  width: 60px;
  text-align: center;
  border: 2px solid #e53935;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.login-form button:hover {
  background: 0 0;
  color: #e53935;
}
.login-form .lost-password a {
  font-weight: 400;
  color: #e53935;
}
.login-form .checkbox {
  padding-left: 20px;
}
.login-form .checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 5px;
  font-weight: 400 !important;
}
.login-form .checkbox label:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  top: 1px;
  margin-left: -20px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.login-form .checkbox label:after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #555;
}
.login-form .checkbox input[type="checkbox"] {
  opacity: 0;
  visibility: hidden;
  height: 0px;
}
.login-form .checkbox input[type="checkbox"]:checked + label::after {
  font-family: FontAwesome;
  content: "\f00c";
}
.login-form .checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #e53935;
  border-color: #e53935;
}
.login-form .checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.modal {
  text-align: center;
  padding: 0 !important;
}
.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.modal-header {
  display: block;
}
.modal-dialog {
  width: 500px;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
.header-menu-fixed {
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 0;
  z-index: 100;
  position: fixed;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 991px) {
  .main-menu-area {
    background-color: transparent;
  }
  .main-menu-area .container {
    width: 100%;
  }
}
.main-menu-area .ne-main-menu {
  text-align: right;
}
.main-menu-area .ne-main-menu nav ul li {
  text-align: left;
  position: relative;
  display: inline-block;
}
.main-menu-area .ne-main-menu nav ul li a {
  /* color: #fff; */
  color: black;
  display: block;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  -webkit-transition: all 0.9s ease-out;
  -moz-transition: all 0.9s ease-out;
  -ms-transition: all 0.9s ease-out;
  -o-transition: all 0.9s ease-out;
  transition: all 0.9s ease-out;
}
.main-menu-area .ne-main-menu nav ul li a:hover {
  color: #e53935;
}
.main-menu-area .ne-main-menu nav ul li ul.ne-dropdown-menu {
  background-color: #e53935;
  box-shadow: none;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  opacity: 0;
  margin: 0;
  min-width: 240px;
  padding: 0;
  top: 100%;
  position: absolute;
  visibility: hidden;
  transform-origin: 0 0 0;
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  z-index: 9999;
}
.main-menu-area .ne-main-menu nav ul li ul.ne-dropdown-menu > li {
  margin-right: 0;
  display: block;
  position: relative;
}
.main-menu-area .ne-main-menu nav ul li ul.ne-dropdown-menu > li a {
  display: block;
  color: #fff;
  text-transform: capitalize;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.main-menu-area .ne-main-menu nav ul li ul.ne-dropdown-menu > li a:focus,
.main-menu-area .ne-main-menu nav ul li ul.ne-dropdown-menu > li a:hover {
  color: #e53935;
  background-color: #111;
}
.main-menu-area .ne-main-menu nav ul li ul.ne-dropdown-menu > li:last-child {
  border-bottom: none;
}
.main-menu-area .ne-main-menu nav ul li:hover ul.ne-dropdown-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1);
}
.header-style1 .main-menu-area .ne-main-menu nav ul li a {
  padding: 30px 12px;
  color: #fff;
}
.header-style1 .main-menu-area .ne-main-menu nav ul li a:hover {
  color: #e53935;
}
@media only screen and (max-width: 1199px) {
  .header-style1 .main-menu-area .ne-main-menu nav ul li a {
    padding: 30px 6px;
  }
}
.header-style1 .main-menu-area .ne-main-menu nav ul li ul.ne-dropdown-menu {
  left: -5px;
  background-color: #e53935;
}
.header-style1
  .main-menu-area
  .ne-main-menu
  nav
  ul
  li
  ul.ne-dropdown-menu
  > li
  a {
  padding: 10px 10px 10px 15px;
  color: #fff;
}
.header-style1
  .main-menu-area
  .ne-main-menu
  nav
  ul
  li
  ul.ne-dropdown-menu
  > li
  a:focus {
  color: #e53935;
  background: #1f1f1f;
}
.header-style1 .main-menu-area .ne-main-menu nav ul .active a {
  color: #e53935;
}
.header-style1
  .main-menu-area
  .ne-main-menu
  nav
  ul
  .active
  ul.ne-dropdown-menu
  .active
  a {
  background-color: #111;
}
.header-style1 .stick .ne-main-menu nav ul li a {
  padding: 22px 12px;
}
@media only screen and (max-width: 1199px) {
  .header-style1 .stick .ne-main-menu nav ul li a {
    padding: 22px 6px;
  }
}
.header-style2 ul.header-social li a {
  color: #787878;
}
.header-style2 ul.header-social li a:hover {
  background-color: #e53935;
  color: #fff;
}
.header-style2 .main-menu-area .ne-main-menu {
  text-align: left;
}
.header-style2 .main-menu-area .ne-main-menu nav ul li a {
  padding: 30px 20px 20px;
  color: #111;
}
.header-style2 .main-menu-area .ne-main-menu nav ul li a:hover {
  color: #e53935;
}
.header-style2 .main-menu-area .ne-main-menu nav ul li ul.ne-dropdown-menu {
  background-color: #e53935;
  left: -15px;
}
.header-style2
  .main-menu-area
  .ne-main-menu
  nav
  ul
  li
  ul.ne-dropdown-menu
  > li
  a {
  padding: 10px 10px 10px 15px;
  color: #fff;
}
.header-style2
  .main-menu-area
  .ne-main-menu
  nav
  ul
  li
  ul.ne-dropdown-menu
  > li
  a:focus {
  color: #e53935;
  background: #f8f8f8;
}
.header-style2 .main-menu-area .ne-main-menu nav ul li:first-child a {
  padding: 30px 20px 20px 0;
}
.header-style2 .main-menu-area .ne-main-menu nav ul .active a {
  color: #e53935;
}
.header-style2
  .main-menu-area
  .ne-main-menu
  nav
  ul
  .active
  ul.ne-dropdown-menu
  .active
  a {
  background-color: #111;
}
.header-style2 .stick {
  background-color: rgba(255, 255, 255, 0.95);
}
.header-style2 .stick .ne-main-menu nav ul li a {
  padding: 22px 20px 20px;
}
.header-style2 .stick .ne-main-menu nav ul li:first-child a {
  padding: 22px 20px 20px 0;
}
@media only screen and (min-width: 992px) {
  .header-style2 .header-action-item ul li {
    border-right: 1px solid #dcdcdc;
  }
  .header-style2 .header-action-item ul li:last-child {
    border-right: 0;
  }
  .header-style2 .header-action-item ul li .search-button i {
    color: #111;
  }
}
.header-style2 .offcanvas-menu-btn a.menu-bar {
  left: -30px;
  top: -20px;
}
.header-style2 .offcanvas-menu-btn a.menu-bar span {
  background-color: #111;
}
.header-style2 .offcanvas-menu-btn a.menu-times {
  left: -30px;
  top: -20px;
}
.header-style2 .offcanvas-menu-btn a.menu-times span {
  background-color: #111;
}
.header-style3 ul.header-social li a,
.header-style3 ul.news-info-list li,
.header-style3 ul.news-info-list li i {
  color: #fff;
}
.header-style3 ul.header-social li a:hover {
  background-color: #e53935;
  color: #fff;
}
.header-style3 .main-menu-area .ne-main-menu {
  text-align: left;
}
.header-style3 .main-menu-area .ne-main-menu nav ul li a {
  padding: 22px 20px;
  color: #fff;
}
.header-style3 .main-menu-area .ne-main-menu nav ul li a:hover {
  color: #e53935;
}
.header-style3 .main-menu-area .ne-main-menu nav ul li ul.ne-dropdown-menu {
  background-color: #e53935;
  left: -15px;
}
.header-style3
  .main-menu-area
  .ne-main-menu
  nav
  ul
  li
  ul.ne-dropdown-menu
  > li
  a {
  padding: 10px 10px 10px 15px;
  color: #fff;
}
.header-style3
  .main-menu-area
  .ne-main-menu
  nav
  ul
  li
  ul.ne-dropdown-menu
  > li
  a:focus {
  color: #e53935;
  background: #f8f8f8;
}
.header-style3 .main-menu-area .ne-main-menu nav ul li:first-child a {
  padding: 22px 20px 22px 0;
}
.header-style3 .main-menu-area .ne-main-menu nav ul .active a {
  color: #e53935;
}
.header-style3
  .main-menu-area
  .ne-main-menu
  nav
  ul
  .active
  ul.ne-dropdown-menu
  .active
  a,
.header-style3 .stick {
  background-color: #111;
}
.header-style3 .stick .ne-main-menu nav ul li a {
  padding: 22px 20px;
}
.header-style3 .stick .ne-main-menu nav ul li:first-child a {
  padding: 22px 20px 22px 0;
}
.header-style3 .stick .container > div {
  background-color: #111;
}
.header-style3 .offcanvas-menu-btn a.menu-bar {
  left: -30px;
  top: -22px;
}
.header-style3 .offcanvas-menu-btn a.menu-bar span {
  background-color: #fff;
}
.header-style3 .offcanvas-menu-btn a.menu-times {
  left: -30px;
  top: -22px;
}
.header-style3 .offcanvas-menu-btn a.menu-times span {
  background-color: #fff;
}
.header-style4 ul.header-social li a {
  color: #787878;
}
.header-style4 ul.header-social li a:hover {
  background-color: #e53935;
  color: #fff;
}
.header-style4 .main-menu-area .ne-main-menu {
  text-align: left;
}
.header-style4 .main-menu-area .ne-main-menu nav ul li a {
  padding: 22px 20px;
  color: #111;
}
.header-style4 .main-menu-area .ne-main-menu nav ul li a:hover {
  color: #e53935;
}
.header-style4 .main-menu-area .ne-main-menu nav ul li ul.ne-dropdown-menu {
  background-color: #e53935;
  left: -15px;
}
.header-style4
  .main-menu-area
  .ne-main-menu
  nav
  ul
  li
  ul.ne-dropdown-menu
  > li
  a {
  padding: 10px 10px 10px 15px;
  color: #fff;
}
.header-style4
  .main-menu-area
  .ne-main-menu
  nav
  ul
  li
  ul.ne-dropdown-menu
  > li
  a:focus {
  color: #e53935;
  background: #f8f8f8;
}
.header-style4 .main-menu-area .ne-main-menu nav ul li:first-child a {
  padding: 22px 20px 22px 0;
}
.header-style4 .main-menu-area .ne-main-menu nav ul .active a {
  color: #e53935;
}
.header-style4
  .main-menu-area
  .ne-main-menu
  nav
  ul
  .active
  ul.ne-dropdown-menu
  .active
  a {
  background-color: #111;
}
.header-style4 .stick {
  background-color: #fff;
}
.header-style4 .stick .ne-main-menu nav ul li a {
  padding: 22px 20px;
}
.header-style4 .stick .ne-main-menu nav ul li:first-child a {
  padding: 22px 20px 22px 0;
}
.header-style4 .offcanvas-menu-btn a.menu-bar {
  left: -30px;
  top: -20px;
}
.header-style4 .offcanvas-menu-btn a.menu-bar span {
  background-color: #111;
}
.header-style4 .offcanvas-menu-btn a.menu-times {
  left: -30px;
  top: -20px;
}
.header-style4 .offcanvas-menu-btn a.menu-times span {
  background-color: #111;
}
.header-style5 ul.header-social li a,
.header-style5 ul.news-info-list li,
.header-style5 ul.news-info-list li i {
  color: #fff;
}
.header-style5 ul.header-social li a:hover {
  background-color: #e53935;
  color: #fff;
}
.header-style5 .main-menu-area .ne-main-menu {
  text-align: left;
}
.header-style5 .main-menu-area .ne-main-menu nav ul li a {
  padding: 22px 18px;
  color: #fff;
}
.header-style5 .main-menu-area .ne-main-menu nav ul li a:hover {
  color: #e53935;
}
.header-style5 .main-menu-area .ne-main-menu nav ul li ul.ne-dropdown-menu {
  background-color: #e53935;
  left: -15px;
}
.header-style5
  .main-menu-area
  .ne-main-menu
  nav
  ul
  li
  ul.ne-dropdown-menu
  > li
  a {
  padding: 10px 10px 10px 15px;
  color: #fff;
}
.header-style5
  .main-menu-area
  .ne-main-menu
  nav
  ul
  li
  ul.ne-dropdown-menu
  > li
  a:focus {
  color: #e53935;
  background: #f8f8f8;
}
.header-style5 .main-menu-area .ne-main-menu nav ul li:first-child a {
  padding: 22px 18px 22px 0;
}
.header-style5 .main-menu-area .ne-main-menu nav ul .active a {
  color: #e53935;
}
.header-style5
  .main-menu-area
  .ne-main-menu
  nav
  ul
  .active
  ul.ne-dropdown-menu
  .active
  a,
.header-style5 .stick {
  background-color: #111;
}
.header-style5 .stick .ne-main-menu nav ul li a {
  padding: 22px 18px;
}
.header-style5 .stick .ne-main-menu nav ul li:first-child a {
  padding: 22px 18px 22px 0;
}
.header-style5 .stick .container > div {
  background-color: #111;
}
.header-style5 .offcanvas-menu-btn a.menu-bar {
  left: -30px;
  top: -22px;
}
.header-style5 .offcanvas-menu-btn a.menu-bar span {
  background-color: #fff;
}
.header-style5 .offcanvas-menu-btn a.menu-times {
  left: -30px;
  top: -22px;
}
.header-style5 .offcanvas-menu-btn a.menu-times span {
  background-color: #fff;
}
.header-style6 ul.news-info-list li i {
  color: #7c7c7c;
}
.header-style6 ul.header-social li a {
  color: #787878;
}
.header-style6 ul.header-social li a:hover {
  background-color: #e53935;
  color: #fff;
}
.header-style6 .main-menu-area .ne-main-menu {
  text-align: right;
}
.header-style6 .main-menu-area .ne-main-menu nav ul li a {
  padding: 30px 10px;
  color: #111;
}
.header-style6 .main-menu-area .ne-main-menu nav ul li a:hover {
  color: #e53935;
}
.header-style6 .main-menu-area .ne-main-menu nav ul li ul.ne-dropdown-menu {
  background-color: #e53935;
  left: -15px;
}
.header-style6
  .main-menu-area
  .ne-main-menu
  nav
  ul
  li
  ul.ne-dropdown-menu
  > li
  a {
  padding: 10px 10px 10px 15px;
  color: #fff;
}
.header-style6
  .main-menu-area
  .ne-main-menu
  nav
  ul
  li
  ul.ne-dropdown-menu
  > li
  a:focus {
  color: #e53935;
  background: #f8f8f8;
}
.header-style6 .main-menu-area .ne-main-menu nav ul li:first-child a {
  padding: 30px 10px 30px 0;
}
.header-style6 .main-menu-area .ne-main-menu nav ul .active a {
  color: #e53935;
}
.header-style6
  .main-menu-area
  .ne-main-menu
  nav
  ul
  .active
  ul.ne-dropdown-menu
  .active
  a {
  background-color: #111;
}
.header-style6 .stick {
  background-color: rgba(255, 255, 255, 0.95);
}
.header-style6 .stick .ne-main-menu nav ul li a {
  padding: 22px 10px;
}
.header-style6 .stick .ne-main-menu nav ul li:first-child a {
  padding: 22px 10px 22px 0;
}
@media only screen and (min-width: 992px) {
  .header-style6 .header-action-item ul li {
    border-right: 1px solid #dcdcdc;
  }
  .header-style6 .header-action-item ul li:last-child {
    border-right: 0;
  }
  .header-style6 .header-action-item ul li .search-button i {
    color: #111;
  }
}
.header-style6 .offcanvas-menu-btn a.menu-bar {
  left: -30px;
  top: -20px;
}
.header-style6 .offcanvas-menu-btn a.menu-bar span {
  background-color: #111;
}
.header-style6 .offcanvas-menu-btn a.menu-times {
  left: -30px;
  top: -20px;
}
.header-style6 .offcanvas-menu-btn a.menu-times span {
  background-color: #111;
}
.header-style7 ul.news-info-list li {
  color: #fff;
}
.header-style7 ul.news-info-list li i {
  color: #e53935;
}
.header-style7 ul.header-social li a {
  color: #fff;
}
.header-style7 ul.header-social li a:hover {
  background-color: #e53935;
  color: #fff;
}
.header-style7 .main-menu-area .ne-main-menu {
  text-align: right;
}
.header-style7 .main-menu-area .ne-main-menu nav ul li a {
  padding: 30px 10px;
  color: #111;
}
.header-style7 .main-menu-area .ne-main-menu nav ul li a:hover {
  color: #e53935;
}
.header-style7 .main-menu-area .ne-main-menu nav ul li ul.ne-dropdown-menu {
  background-color: #e53935;
  left: -15px;
}
.header-style7
  .main-menu-area
  .ne-main-menu
  nav
  ul
  li
  ul.ne-dropdown-menu
  > li
  a {
  padding: 10px 10px 10px 15px;
  color: #fff;
}
.header-style7
  .main-menu-area
  .ne-main-menu
  nav
  ul
  li
  ul.ne-dropdown-menu
  > li
  a:focus {
  color: #e53935;
  background: #f8f8f8;
}
.header-style7 .main-menu-area .ne-main-menu nav ul li:first-child a {
  padding: 30px 10px 30px 0;
}
.header-style7 .main-menu-area .ne-main-menu nav ul .active a {
  color: #e53935;
}
.header-style7
  .main-menu-area
  .ne-main-menu
  nav
  ul
  .active
  ul.ne-dropdown-menu
  .active
  a {
  background-color: #111;
}
.header-style7 .stick {
  background-color: rgba(255, 255, 255, 0.95);
}
.header-style7 .stick .ne-main-menu nav ul li a {
  padding: 22px 10px;
}
.header-style7 .stick .ne-main-menu nav ul li:first-child a {
  padding: 22px 10px 22px 0;
}
@media only screen and (min-width: 992px) {
  .header-style7 .header-action-item ul li {
    border-right: 1px solid #dcdcdc;
  }
  .header-style7 .header-action-item ul li:last-child {
    border-right: 0;
  }
  .header-style7 .header-action-item ul li .search-button i {
    color: #111;
  }
}
.header-style7 .offcanvas-menu-btn a.menu-bar {
  left: -30px;
  top: -20px;
}
.header-style7 .offcanvas-menu-btn a.menu-bar span {
  background-color: #111;
}
.header-style7 .offcanvas-menu-btn a.menu-times {
  left: -30px;
  top: -20px;
}
.header-style7 .offcanvas-menu-btn a.menu-times span {
  background-color: #111;
}
.mean-container .mean-bar {
  float: none;
  z-index: 99;
  background: 0 0;
  padding: 0;
}
.mean-container .mean-bar:after {
  content: "";
  clear: both;
  display: block;
}
.mean-container .mean-bar .meanmenu-reveal {
  left: 0 !important;
  right: inherit;
  top: 7px;
  position: absolute;
  color: #fff;
}
.mean-container .mean-bar .meanmenu-reveal span {
  background: #fff;
}
.mean-container .mean-bar .mean-nav {
  background: 0 0;
}
.mean-container .mean-bar .mean-nav ul li a {
  color: #111;
  font-weight: 700;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.mean-container .mean-bar .mean-nav ul li a:hover {
  color: #e53935;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.mean-container .mean-bar .mean-nav ul li li a {
  color: #111;
  opacity: 1;
  border-top: 1px solid #ddd;
}
.mean-container .mean-bar .mean-nav > ul {
  overflow-y: scroll;
}
.mean-container .mean-bar .mean-nav > ul:after {
  content: "";
  display: block;
  clear: both;
}
.mean-container .mean-bar .mobile-menu-nav-back {
  border-bottom: 1px solid #3a3a3a;
  position: relative;
  text-align: center;
  height: 60px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.mean-container .mean-bar .mobile-menu-nav-back:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: #111;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
}
.mean-container .mean-bar .mobile-menu-nav-back .logo-mobile {
  box-sizing: border-box;
  padding: 0;
  margin: 0 auto;
}
.mean-container .mean-bar + div#wrapper {
  padding-top: 59px;
}
.ticker-wrapper.has-js {
  width: 100%;
  display: block;
}
.ticker {
  width: 100%;
  height: 23px;
  display: block;
  position: relative;
  overflow: hidden;
}
.ticker-content {
  margin: 0;
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.5;
}
.ticker-content a {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.ticker-content a:hover {
  text-decoration: none;
  color: #e53935;
}
.ticker-swipe {
  padding-top: 9px;
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  height: 23px;
}
.ticker-swipe span {
  margin-left: 1px;
  border-bottom: 1px solid #e53935;
  height: 12px;
  width: 7px;
  display: block;
}
.ticker-controls {
  padding: 8px 0 0;
  list-style-type: none;
  float: left;
}
.ticker-controls li {
  padding: 0;
  margin-left: 5px;
  float: left;
  cursor: pointer;
  height: 16px;
  width: 16px;
  display: block;
}
.left .ticker,
.left .ticker-content,
.left .ticker-controls,
.left .ticker-title {
  float: left;
}
.feeding-text-dark .ticker,
.feeding-text-dark .ticker-content,
.feeding-text-dark .ticker-wrapper.has-js {
  background-color: #f8f8f8;
}
.feeding-text-dark .ticker-content a {
  color: #444;
}
.feeding-text-dark .ticker-content a:hover {
  color: #e53935;
}
.feeding-text-dark .ticker-swipe,
.feeding-text-dark .ticker-swipe span {
  background-color: #f8f8f8;
}
.feeding-text-dark2 .ticker,
.feeding-text-dark2 .ticker-content,
.feeding-text-dark2 .ticker-wrapper.has-js {
  background-color: #fff;
}
.feeding-text-dark2 .ticker-content a {
  color: #444;
}
.feeding-text-dark2 .ticker-content a:hover {
  color: #e53935;
}
.feeding-text-dark2 .ticker-swipe,
.feeding-text-dark2 .ticker-swipe span {
  background-color: #fff;
}
.feeding-text-light .ticker,
.feeding-text-light .ticker-content,
.feeding-text-light .ticker-wrapper.has-js {
  background-color: #111;
}
.feeding-text-light .ticker-content a {
  color: #fff;
}
.feeding-text-light .ticker-content a:hover {
  color: #e53935;
}
.feeding-text-light .ticker-swipe,
.feeding-text-light .ticker-swipe span {
  background-color: #111;
}
.feeding-text-light2 .ticker,
.feeding-text-light2 .ticker-content,
.feeding-text-light2 .ticker-wrapper.has-js {
  background-color: #444;
}
.feeding-text-light2 .ticker-content a {
  color: #fff;
}
.feeding-text-light2 .ticker-content a:hover {
  color: #e53935;
}
.feeding-text-light2 .ticker-swipe,
.feeding-text-light2 .ticker-swipe span {
  background-color: #444;
}
.img-overlay-slider {
  position: relative;
}
.img-overlay-slider:before {
  opacity: 0.7;
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#111),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(transparent, #111);
  background-image: -moz-linear-gradient(transparent, #111),
    -moz-linear-gradient(transparent, #111);
  background-image: -o-linear-gradient(transparent, #111),
    -o-linear-gradient(transparent, #111);
  background-image: linear-gradient(transparent, #111),
    linear-gradient(transparent, #111);
  content: "";
  height: 70%;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  z-index: 6;
}
.slider-title {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  font-family: Poppins, sans-serif;
  line-height: 1.5;
  width: 70%;
}
@media only screen and (max-width: 767px) {
  .slider-title {
    font-size: 20px;
    width: 100%;
  }
}
@media only screen and (max-width: 575px) {
  .slider-title {
    font-size: 18px;
  }
}
.main-slider1 .preview-1 .nivo-controlNav {
  position: absolute;
}
.preview-1 .nivo-controlNav a.active,
.preview-1 .nivo-controlNav a:hover {
  background: #e53935;
  cursor: pointer;
}
.nivo-caption {
  top: 70px;
  opacity: 1;
}
@media only screen and (max-width: 991px) {
  .nivo-caption {
    top: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .nivo-caption {
    top: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .nivo-caption {
    top: 0;
  }
  .d-xs-none {display:  none; }
  .slide img {

  height : 220px;
  width : 100%;
  
}

.media-md {
  height : 200px;
  width : 100%;
}

h3.title-medium-dark a {
  font-size: 12px;
}
.post-date-dark ul li {
  font-size: 11px;
}
.post-date-dark ul li span {
  font-size : 11px;
}
.sports-sm {
  width : 100%;
  height : 100px;
}
.news_img {
  height : 200px;
  width : 100%;
}

.nav-link.active{
  color:#000 !important;
  font-weight: 500px;
}

.nav-link:hover{
  color:#000 !important;
}



.navbar-toggler {
  position: absolute;
    right: 15px;
    top: -48px;
    
}
.media-smm {
  width: 100%;
  height: 220px;
}

.media-smm img {
  width: 100%;
  height: 220px;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 30px !important;
    height: 30px !important; 
  }

.nav-item .nav-link {
  font-size: 14px;

}

.dropdown-menu {
  font-size: 14px;
}
.mb-input-search {
 font-size: 14px;
  width: 95% !important;
  padding-left: 0px !important;
  margin-left: 0px !important;
  text-align: left !important;  
}

.mb-input-search  input  {
  font-size: 14px;
}

.mb-input-search .btn  {
  padding: 5px 15px;
    font-size: 15px;
}
.react-responsive-modal-modal {
  width : 85%; 
}

.Modal-content {
  width : 100% !important; 
}

}
.preview-1 .nivo-controlNav {
  bottom: 20px;
  right: inherit;
  left: 55px;
}
@media only screen and (max-width: 767px) {
  .preview-1 .nivo-controlNav {
    bottom: 10px;
  }
}
@media only screen and (max-width: 575px) {
  .preview-1 .nivo-controlNav {
    bottom: 0;
    left: 27px;
  }
}
.preview-1 .nivo-controlNav a {
  border: 0;
  display: inline-block;
  height: 12px;
  margin: 0 5px;
  text-indent: -9999px;
  width: 12px;
  line-height: 8px;
  background: #c4c7cb;
  cursor: pointer;
  position: relative;
  opacity: 1;
  z-index: -999;
  border-radius: 50%;
}
.main-slider1 .slide-1 .topic-box-sm,
.main-slider1 .slide-2 .topic-box-sm,
.main-slider1 .slide-3 .topic-box-sm {
  -webkit-animation: fadeInRight 1000ms ease-in-out;
  -moz-animation: fadeInRight 1000ms ease-in-out;
  -ms-animation: fadeInRight 1000ms ease-in-out;
  animation: fadeInRight 1000ms ease-in-out;
}
.main-slider1 .slide-1 .post-date-light,
.main-slider1 .slide-2 .post-date-light,
.main-slider1 .slide-3 .post-date-light {
  -webkit-animation: fadeInRight 1500ms ease-in-out;
  -moz-animation: fadeInRight 1500ms ease-in-out;
  -ms-animation: fadeInRight 1500ms ease-in-out;
  animation: fadeInRight 1500ms ease-in-out;
}
.main-slider1 .slide-1 .slider-title,
.main-slider1 .slide-2 .slider-title,
.main-slider1 .slide-3 .slider-title {
  -webkit-animation: fadeInRight 2000ms ease-in-out;
  -moz-animation: fadeInRight 2000ms ease-in-out;
  -ms-animation: fadeInRight 2000ms ease-in-out;
  animation: fadeInRight 2000ms ease-in-out;
}
.category-box-layout1 {
  position: relative;
}
.category-box-layout1 .ctg-title-xs {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 10px;
  display: block;
}
.category-box-layout1 .content {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
}
ul.stay-connected li {
  width: 25%;
  border-right: 1px solid #dcdcdc;
  text-align: center;
  display: inline-block;
  float: left;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
ul.stay-connected li:last-child {
  border-right: 0;
  padding-right: 0;
  margin-right: 0;
}
ul.stay-connected li i {
  margin: 0 auto 10px;
  color: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: block;
  line-height: 50px;
  font-size: 18px;
}
ul.stay-connected li .connection-quantity {
  font-weight: 600;
  font-size: 18px;
  color: #111;
  margin-bottom: 10px;
}
ul.stay-connected li p {
  font-size: 14px;
  margin-bottom: 0;
  line-height: 1;
  color: #444;
}
ul.stay-connected li:hover {
  opacity: 0.8;
}
ul.stay-connected .facebook i {
  background-color: #3b5998;
}
ul.stay-connected .twitter i {
  background-color: #0091ea;
}
ul.stay-connected .linkedin i {
  background-color: #1976d2;
}
ul.stay-connected .rss i {
  background-color: #ef6c00;
}
ul.stay-connected-color li {
  width: 24%;
  text-align: center;
  float: left;
  margin-right: 3px;
  display: inline-block;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
ul.stay-connected-color li:last-child {
  margin-right: 0;
}
ul.stay-connected-color li a {
  padding: 25px 0;
  display: block;
}
ul.stay-connected-color li i {
  color: #fff;
  display: block;
  font-size: 18px;
}
ul.stay-connected-color li .connection-quantity {
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  margin-bottom: 5px;
}
ul.stay-connected-color li p {
  font-size: 11px;
  margin-bottom: 0;
  line-height: 1;
  color: #fff;
}
ul.stay-connected-color li:hover {
  opacity: 0.8;
}
ul.stay-connected-color .facebook {
  background-color: #3b5998;
}
ul.stay-connected-color .twitter {
  background-color: #0091ea;
}
ul.stay-connected-color .linkedin {
  background-color: #1976d2;
}
ul.stay-connected-color .rss {
  background-color: #ef6c00;
}
ul.stay-connected-light li {
  margin-bottom: 2px;
  -webkit-box-shadow: 0 1px 0 0 rgba(211, 211, 211, 0.75);
  -moz-box-shadow: 0 1px 0 0 rgba(211, 211, 211, 0.75);
  box-shadow: 0 1px 0 0 rgba(211, 211, 211, 0.75);
  background-color: #fff;
  width: 24%;
  text-align: center;
  display: inline-block;
  margin-right: 3px;
  float: left;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
ul.stay-connected-light li:last-child {
  border-right: 0;
  padding-right: 0;
  margin-right: 0;
}
ul.stay-connected-light li a {
  padding: 15px 0;
  display: block;
}
ul.stay-connected-light li i {
  margin: 0 auto 10px;
  color: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: block;
  line-height: 50px;
  font-size: 18px;
}
ul.stay-connected-light li .connection-quantity {
  font-weight: 600;
  font-size: 18px;
  color: #111;
  margin-bottom: 10px;
}
ul.stay-connected-light li p {
  font-size: 12px;
  margin-bottom: 0;
  line-height: 1;
  color: #444;
}
ul.stay-connected-light li:hover {
  background-color: #fafafa;
}
ul.stay-connected-light .facebook i {
  background-color: #3b5998;
}
ul.stay-connected-light .twitter i {
  background-color: #0091ea;
}
ul.stay-connected-light .linkedin i {
  background-color: #1976d2;
}
ul.stay-connected-light .rss i {
  background-color: #ef6c00;
}
.newsletter-area {
  text-align: center;
  padding: 45px 30px;
}
.newsletter-area .stylish-input-group {
  border: none;
  padding: 0;
}
.newsletter-area .stylish-input-group .form-control {
  border: none;
  box-shadow: 0 0 0;
  text-transform: lowercase;
  color: #444;
  height: 42px;
  background-color: #fff;
  border-radius: 4px 0 0 4px;
  font-size: 15px;
}
.newsletter-area .stylish-input-group .input-group-addon {
  background: 0 0;
  border: none;
  padding: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.newsletter-area .stylish-input-group .input-group-addon button {
  border-radius: 0 4px 4px 0;
  border: 0;
  padding: 0 17px;
  height: 42px;
  background-color: #111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.newsletter-area .stylish-input-group .input-group-addon button i {
  font-size: 20px;
  color: #fff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.newsletter-area .stylish-input-group .input-group-addon button:hover {
  background: #ddd;
}
.newsletter-area .stylish-input-group .input-group-addon button:hover i {
  color: #111;
}
.newsletter-area input.form-control::-webkit-input-placeholder,
.newsletter-area textarea.form-control::-webkit-input-placeholder {
  color: #444;
}
.newsletter-area input.form-control::-moz-placeholder,
.newsletter-area textarea.form-control::-moz-placeholder {
  color: #444;
}
.newsletter-area input.form-control:-moz-placeholder,
.newsletter-area textarea.form-control:-moz-placeholder {
  color: #444;
}
.newsletter-area input.form-control:-ms-input-placeholder,
.newsletter-area textarea.form-control:-ms-input-placeholder {
  color: #444;
}
.newsletter-area p {
  color: #fff;
}
ul.sidebar-tags {
  display: inline-block;
  margin-bottom: -5px;
}
ul.sidebar-tags li {
  text-transform: capitalize;
  display: inline-block;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
}
ul.sidebar-tags li a {
  border: 1px solid #dcdcdc;
  display: inline-block;
  padding: 10px 20px;
  color: #444;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
ul.sidebar-tags li a:hover {
  background-color: #e53935;
  color: #fff;
  border: 1px solid #e53935;
}
ul.sidebar-tags2 {
  display: inline-block;
  margin-bottom: -5px;
}
ul.sidebar-tags2 li {
  text-transform: capitalize;
  display: inline-block;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
}
ul.sidebar-tags2 li a {
  border: 1px solid #dcdcdc;
  display: inline-block;
  padding: 7px 10px;
  color: #444;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
ul.sidebar-tags2 li a:hover {
  background-color: #e53935;
  color: #fff;
  border: 1px solid #e53935;
}
ul.archive-list li {
  position: relative;
  margin-bottom: 12px;
}
ul.archive-list li:last-child {
  margin-bottom: 0;
}
ul.archive-list li a {
  color: #444;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
ul.archive-list li a:hover {
  color: #e53935;
}
ul.archive-list li span {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
ul.archive-list2 li {
  position: relative;
  margin-bottom: 14px;
}
ul.archive-list2 li:last-child {
  margin-bottom: 0;
}
ul.archive-list2 li a {
  color: #444;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
ul.archive-list2 li a:hover {
  color: #e53935;
}
ul.archive-list2 li span {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.post-by {
  font-style: italic;
  font-size: 14px;
}
ul.author-social-style1 li {
  height: 40px;
  width: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #fff;
  line-height: 40px;
  text-align: center;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
ul.author-social-style1 li a {
  color: #e53935;
  display: block;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
ul.author-social-style1 li:hover {
  border: 1px solid #e53935;
}
ul.author-social-style2 li {
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #dcdcdc;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
ul.author-social-style2 li a {
  color: #e53935;
  display: block;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
ul.author-social-style2 li:hover {
  background-color: #e53935;
  border: 1px solid #e53935;
}
ul.author-social-style2 li:hover a {
  color: #fff;
}
ul.address-info {
  margin-bottom: 50px;
}
ul.address-info li {
  font-size: 16px;
  color: #111;
  position: relative;
  display: inline-flex;
  width: 49%;
  margin-bottom: 15px;
  padding-left: 35px;
}
@media only screen and (max-width: 767px) {
  ul.address-info li {
    width: 100%;
    display: block;
  }
}
ul.address-info li i {
  font-size: 24px;
  position: absolute;
  left: 0;
  top: 2px;
  z-index: 1;
  color: #e53935;
}
.contact-form .form-group {
  margin-bottom: 20px;
}
.contact-form .form-group .form-control {
  color: #111;
  font-weight: 600;
  font-size: 14px;
  border-radius: 0;
  background: #f4f4f4;
  border: none;
  -webkit-box-shadow: 0 1px 0 0 rgba(216, 216, 216, 0.75);
  -moz-box-shadow: 0 1px 0 0 rgba(216, 216, 216, 0.75);
  box-shadow: 0 1px 0 0 rgba(216, 216, 216, 0.75);
}
.contact-form .form-group input {
  padding: 0.5rem 1.5rem;
  height: 45px;
}
.contact-form .form-group textarea {
  padding: 15px 1.5rem 1.5rem;
}
.contact-form .form-group button {
  margin-top: 20px;
}
.contact-form .form-response .alert-info {
  background: 0 0 !important;
  border: none !important;
  color: red;
  letter-spacing: 5px;
}
.contact-form .form-response .alert-success {
  background-color: transparent;
  color: green;
  letter-spacing: 4px;
  border-color: transparent;
  font-weight: 500;
}
.contact-form .form-response .alert {
  border-radius: 0 !important;
  padding: 35px 0 15px;
  margin-bottom: 0 !important;
}
.contact-form input.search-input:focus {
  outline: 0;
}
.contact-form input::-webkit-input-placeholder,
.contact-form textarea::-webkit-input-placeholder {
  color: #444;
}
.contact-form input::-moz-placeholder,
.contact-form textarea::-moz-placeholder {
  color: #444;
}
.contact-form input:-moz-placeholder,
.contact-form textarea:-moz-placeholder {
  color: #444;
}
.contact-form input:-ms-input-placeholder,
.contact-form textarea:-ms-input-placeholder {
  color: #444;
}
.archive-search-box {
  padding: 10px 10px 5px;
  margin-bottom: 30px;
}
.archive-search-box .form-group {
  margin: 0 0 5px;
}
.ne-custom-select select {
  width: 100%;
  height: 40px;
}
.select2-container--classic
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #111 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}
.select2-container--open.select2-container--below .select2-selection--single {
  border: 1px solid #dcdcdc;
}
.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #111 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}
.select2-container--open.select2-container--above .select2-selection--single {
  border: 1px solid #dcdcdc;
}
.select2-container .select2-selection--single {
  height: 40px;
}
.select2-container--classic .select2-selection--single {
  background-color: #fff;
  background-image: none;
  border-radius: 0;
  border: 1px solid #dcdcdc;
}
.select2-container--classic
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 40px;
  color: #707070;
  font-weight: 400 !important;
}
.select2-container--classic
  .select2-selection--single
  .select2-selection__arrow {
  top: 7px;
  background-color: transparent;
  background-image: none;
  border-style: none;
  right: 5px;
}
.select2-container--classic .select2-dropdown {
  border: 1px solid #dcdcdc !important;
  border-radius: 0;
}
.select2-container--classic
  .select2-results__option--highlighted[aria-selected] {
  background-color: #e53935;
  color: #fff;
  font-weight: 600;
}
.select2-container--classic .select2-selection--single:focus,
.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #dcdcdc;
}
.select2-container--classic.select2-container--open.select2-container--below
  .select2-selection--single {
  background-image: none;
  background-color: #fff;
}
.select2-container--classic.select2-container--open.select2-container--above
  .select2-selection--single {
  background-image: none;
  background-color: #fff;
  border-top: 1px solid #dcdcdc;
}
.select2-container--classic .select2-dropdown {
  width: 160px !important;
  z-index: 8;
}
.pagination-btn-wrapper ul li {
  margin-right: 5px;
  display: inline-block;
}
.pagination-btn-wrapper ul li a {
  font-weight: 600;
  font-size: 16px;
  background-color: #f8f8f8;
  color: #111;
  display: inline-block;
  height: 40px;
  width: 44px;
  text-align: center;
  line-height: 40px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.pagination-btn-wrapper ul .active a,
.pagination-btn-wrapper ul li a:hover {
  background-color: #e53935;
  color: #fff;
}
.news-details-layout1 p,
.news-details-layout2 p,
.news-details-layout3 p {
  font-size: 16px;
  line-height: 28px;
}
blockquote {
  display: inline-block;
  padding: 30px 15px 35px 80px;
  position: relative;
  font-size: 18px;
  line-height: 30px;
  font-style: italic;
  border-left: 3px solid #e53935;
}
@media only screen and (max-width: 575px) {
  blockquote {
    padding: 30px 20px 35px 50px;
  }
}
blockquote:before {
  content: "\f10d";
  position: absolute;
  left: 35px;
  top: 25px;
  color: #e53935;
  font-size: 36px;
  z-index: 1;
  font-family: fontawesome;
}
@media only screen and (max-width: 575px) {
  blockquote:before {
    left: 5px;
  }
}
ul.post-info-dark li {
  display: inline-block;
  margin-right: 20px;
  padding-right: 20px;
  border-right: 2px solid #dcdcdc;
  line-height: 1;
}
ul.post-info-dark li:first-child a {
  font-weight: 600;
}
ul.post-info-dark li:last-child {
  border-right: 0;
}
ul.post-info-dark li a {
  font-size: 14px;
  color: #444;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
ul.post-info-dark li a:hover {
  color: #e53935;
}
ul.post-info-dark li a i {
  margin-right: 15px;
}
ul.post-info-dark li span {
  color: #e53935;
}
ul.post-info-light li {
  display: inline-block;
  margin-right: 20px;
  padding-right: 20px;
  border-right: 2px solid #dcdcdc;
  line-height: 1;
}
ul.post-info-light li:first-child a {
  font-weight: 600;
}
ul.post-info-light li:last-child {
  border-right: 0;
}
ul.post-info-light li a {
  font-size: 14px;
  color: #fff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
ul.post-info-light li a:hover {
  color: #e53935;
}
ul.post-info-light li a i {
  margin-right: 15px;
}
ul.post-info-light li span {
  color: #e53935;
}
ul.blog-tags {
  margin-bottom: 30px;
  margin-top: 30px;
}
ul.blog-tags li {
  font-size: 13px;
}
ul.blog-tags li a {
  border: 1px solid #dcdcdc;
  display: block;
  padding: 3px 10px;
  color: #444;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
ul.blog-tags li a:hover {
  color: #fff;
  background-color: #111;
  border: 1px solid #111;
}
ul.blog-tags li:first-child {
  border: 1px solid #e53935;
  padding: 3px 15px;
  background-color: #e53935;
  color: #fff;
  font-size: 14px;
}
.post-share-area {
  padding: 20px;
  background-color: #f8f8f8;
}
.post-share-area p {
  font-size: 16px;
  font-weight: 600;
}
.post-share-area ul.social-default li a.facebook {
  background: #516eab;
  border: 2px solid #516eab;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.post-share-area ul.social-default li a.facebook:hover {
  background: 0 0;
  color: #516eab;
}
.post-share-area ul.social-default li a.twitter {
  background: #29c5f6;
  border: 2px solid #29c5f6;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.post-share-area ul.social-default li a.twitter:hover {
  background: 0 0;
  color: #29c5f6;
}
.post-share-area ul.social-default li a.google {
  background: #eb4026;
  border: 2px solid #eb4026;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.post-share-area ul.social-default li a.google:hover {
  background: 0 0;
  color: #eb4026;
}
.post-share-area ul.social-default li a.pinterest {
  background: #ca212a;
  border: 2px solid #ca212a;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.post-share-area ul.social-default li a.pinterest:hover {
  background: 0 0;
  color: #ca212a;
}
.post-share-area ul.social-default li a.rss {
  background: #ef6c00;
  border: 2px solid #ef6c00;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.post-share-area ul.social-default li a.rss:hover {
  background: 0 0;
  color: #ef6c00;
}
.post-share-area ul.social-default li a.linkedin {
  background: #1976d2;
  border: 2px solid #1976d2;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.post-share-area ul.social-default li a.linkedin:hover {
  background: 0 0;
  color: #1976d2;
}
.post-share-area ul.social-default li:last-child {
  margin-right: 0;
}
.post-share-area ul.social-default li a {
  display: block;
  height: 44px;
  line-height: 42px;
  width: 48px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.blog-post-slider {
  padding: 0 0 30px;
}
.blog-post-slider .prev-article {
  color: #444;
  margin-bottom: 10px;
  display: block;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.blog-post-slider .prev-article i {
  margin-right: 10px;
  color: #e53935;
  font-size: 20px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.blog-post-slider .prev-article:hover {
  color: #e53935;
}
.blog-post-slider .next-article {
  color: #444;
  margin-bottom: 10px;
  display: block;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.blog-post-slider .next-article i {
  margin-left: 10px;
  color: #e53935;
  font-size: 20px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.blog-post-slider .next-article:hover {
  color: #e53935;
}
.comments-area ul li {
  margin-bottom: 4rem;
  padding-bottom: 2rem;
  position: relative;
  border-bottom: 1px solid #dcdcdc;
}
.comments-area ul li .comments-content {
  padding-top: 10px;
}
.comments-area ul li .comments-content h3 a span {
  color: #444;
  font-weight: 400;
}
.comments-area ul li:nth-child(even) {
  margin-left: 4rem;
}
.comments-area ul li:nth-child(even) .comments-content span {
  right: 30px;
}
.leave-comments .form-group {
  margin-bottom: 20px;
}
.leave-comments .form-group .form-control {
  color: #111;
  font-weight: 600;
  font-size: 14px;
  border-radius: 0;
  background: #f4f4f4;
  border: none;
  -webkit-box-shadow: 0 1px 0 0 rgba(216, 216, 216, 0.75);
  -moz-box-shadow: 0 1px 0 0 rgba(216, 216, 216, 0.75);
  box-shadow: 0 1px 0 0 rgba(216, 216, 216, 0.75);
}
.leave-comments .form-group input {
  padding: 0.5rem 1.5rem;
  height: 45px;
}
.leave-comments .form-group textarea {
  padding: 15px 1.5rem 1.5rem;
}
.leave-comments .form-group button {
  margin-top: 20px;
}
.leave-comments .form-response .alert-info {
  background: 0 0 !important;
  border: none !important;
  color: red;
  letter-spacing: 5px;
}
.leave-comments .form-response .alert-success {
  background-color: transparent;
  color: green;
  letter-spacing: 4px;
  border-color: transparent;
  font-weight: 500;
}
.leave-comments .form-response .alert {
  border-radius: 0 !important;
  padding: 35px 0 15px;
  margin-bottom: 0 !important;
}
.leave-comments input.search-input:focus {
  outline: 0;
}
.leave-comments input::-webkit-input-placeholder,
.leave-comments textarea::-webkit-input-placeholder {
  color: #444;
}
.leave-comments input::-moz-placeholder,
.leave-comments textarea::-moz-placeholder {
  color: #444;
}
.leave-comments input:-moz-placeholder,
.leave-comments textarea:-moz-placeholder {
  color: #444;
}
.leave-comments input:-ms-input-placeholder,
.leave-comments textarea:-ms-input-placeholder {
  color: #444;
}
.breadcrumbs-area {
  text-align: left;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: block;
  padding: 33px 0;
}
.breadcrumbs-area:after {
  position: absolute;
  content: "";
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.breadcrumbs-area .breadcrumbs-content {
  position: relative;
  z-index: 1;
}
.breadcrumbs-area .breadcrumbs-content h1 {
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 2px;
  color: #fff;
  font-size: 28px;
}
@media only screen and (max-width: 767px) {
  .breadcrumbs-area .breadcrumbs-content h1 {
    font-size: 26px;
  }
}
.breadcrumbs-area .breadcrumbs-content ul li {
  display: inline-block;
  color: #fff;
}
.breadcrumbs-area .breadcrumbs-content ul li a {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  color: #9d9c9c;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.breadcrumbs-area .breadcrumbs-content ul li a:hover {
  color: #e53935;
}
.breadcrumbs-area2 {
  display: block;
  padding: 33px 0;
  text-align: left;
  background-color: #f8f8f8;
}
.breadcrumbs-area2 .breadcrumbs-content {
  position: relative;
  z-index: 1;
}
.breadcrumbs-area2 .breadcrumbs-content h1 {
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 2px;
  color: #111;
  font-size: 28px;
}
.breadcrumbs-area2 .breadcrumbs-content ul li {
  display: inline-block;
  color: #e53935;
}
.breadcrumbs-area2 .breadcrumbs-content ul li a {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  color: #9d9c9c;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.breadcrumbs-area2 .breadcrumbs-content ul li a:hover {
  color: #e53935;
}
.inner-page-banner {
  position: relative;
}
.inner-page-banner .container {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 80px;
  z-index: 7;
}
@media only screen and (max-width: 575px) {
  .inner-page-banner .container {
    bottom: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .inner-page-banner .container {
    bottom: 20px;
  }
}
.inner-page-banner .container .banner-content .inner-banner-title {
  font-size: 40px;
  line-height: 48px;
  color: #fff;
  margin-bottom: 25px;
}
@media only screen and (max-width: 1199px) {
  .inner-page-banner .container .banner-content .inner-banner-title {
    font-size: 36px;
    line-height: 40px;
  }
}
@media only screen and (max-width: 991px) {
  .inner-page-banner .container .banner-content .inner-banner-title {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .inner-page-banner .container .banner-content .inner-banner-title {
    font-size: 26px;
    margin-bottom: 0;
    line-height: 32px;
  }
}
@media only screen and (max-width: 575px) {
  .inner-page-banner .container .banner-content .inner-banner-title {
    font-size: 22px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 575px) {
  .inner-page-banner .container .banner-content .inner-banner-title {
    font-size: 20px;
    line-height: 26px;
  }
}
.gallery-layout-1 {
  position: relative;
}
.gallery-layout-2 {
  margin-bottom: 4px;
  position: relative;
}
.gallery-layout-2 .mask-content-xs {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.gallery-layout-2:hover .mask-content-xs {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.gallery-layout-2:hover .topic-box-sm:after {
  background-color: #e53935;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.on-hover-bg-gray {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.on-hover-bg-gray:hover {
  background-color: #333437;
}
.games-review-box {
  margin-bottom: 30px;
}
.games-review-box p {
  line-height: 29px;
  color: #d2d2d2;
}
.banner-content-style1 {
  position: absolute;
  bottom: 70px;
  left: 0;
  right: 0;
  z-index: 7;
}
@media only screen and (max-width: 767px) {
  .banner-content-style1 {
    bottom: 0;
  }
}
footer {
  background-color: #000;
}
footer .footer-area-top {
  padding: 60px 0 10px;
  border-bottom: 1px solid #4d4d4d;
}
footer .footer-box {
  width: 100%;
  margin-bottom: 40px;
  display: inline-block;
}
footer .footer-box ul.most-view-post li {
  margin-bottom: 15px;
}
footer .footer-box ul.most-view-post li:last-child {
  margin-bottom: 0;
}
footer .footer-box ul.most-view-post li .media img {
  margin-top: 5px;
}
footer .footer-box ul.most-view-post li .media > .pull-left {
  padding-right: 15px;
}
footer .footer-box ul.most-view-post li .post-date-light ul li {
  color: #aeaeae;
  font-size: 14px;
  margin-bottom: 0;
}
footer .footer-box ul.most-view-post li a img {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
footer .footer-box ul.most-view-post li a:hover img {
  opacity: 0.8;
}
footer .footer-box ul.popular-categories li {
  margin-right: 20px;
  margin-bottom: 10px;
  position: relative;
}
footer .footer-box ul.popular-categories li:last-child {
  margin-bottom: 0;
}
footer .footer-box ul.popular-categories li a {
  display: block;
  color: #fff;
  font-size: 14px;
  font-family: Poppins, sans-serif;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
footer .footer-box ul.popular-categories li a span {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  font-size: 14px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
footer .footer-box ul.popular-categories li:hover a {
  color: #e53935;
}
footer .footer-box ul.popular-categories li:hover a span {
  font-size: 20px;
}
footer .footer-box ul.popular-categories li:hover:before {
  right: 0;
  left: 0;
  width: 100%;
}
footer .footer-box ul.popular-categories li:before {
  content: "";
  background-color: #e53935;
  width: 0;
  height: 1px;
  left: 0;
  bottom: 0;
  position: absolute;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
footer .footer-box ul.post-gallery li {
  display: inline-table;
  margin-bottom: 20px;
  margin-right: 15px;
  float: left;
}
footer .footer-box ul.post-gallery figure {
  margin: 0;
  padding: 0;
  background: 0 0;
  overflow: hidden;
  position: relative;
}
footer .footer-box ul.post-gallery figure::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
footer .footer-box ul.post-gallery figure:hover::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
footer .footer-area-bottom {
  padding: 60px 0 50px;
}
footer .footer-area-bottom .footer-logo {
  margin-bottom: 30px;
  display: block;
}
footer .footer-area-bottom .footer-logo img {
  margin: 0 auto;
}
footer .footer-area-bottom p {
  color: #b9b9b9;
}
ul.footer-social {
  margin-bottom: 30px;
}
ul.footer-social li {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 8px;
}
ul.footer-social li:last-child {
  margin-right: 0;
}
ul.footer-social li a {
  width: 45px;
  height: 40px;
  line-height: 40px;
  background-color: #404040;
  text-align: center;
  color: #fff;
  display: block;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
ul.footer-social li a:hover {
  background-color: #e53935;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-50 {
  margin-top: 50px;
}
.mr-40 {
  margin-right: 40px;
}
.mt-4 {
  margin-top: 4px !important;
}
.mb-1 {
  margin-bottom: 1px !important;
}
.mb-2 {
  margin-bottom: 2px !important;
}
.mb-3 {
  margin-bottom: 3px !important;
}
.mb-4 {
  margin-bottom: 4px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-20-r {
  margin-bottom: 2rem;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-50-r {
  margin-bottom: 5rem;
}
.mb-60 {
  margin-bottom: 60px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-100 {
  padding-top: 100px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-50 {
  padding-right: 50px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-50 {
  padding-left: 50px;
}
.p-20-r {
  padding: 2rem;
}
.p-30-r {
  padding: 3rem;
}
.p-35-r {
  padding: 3.5rem;
}
.mt-20-r {
  margin-top: 2rem;
}
.topic-box-margin {
  margin-top: 4px;
  margin-bottom: 5px;
}
.box-padding15 {
  padding: 1rem 1.5rem 0.1rem;
}
.box-padding20 {
  padding: 1.5rem 2rem 0.5rem;
}
.box-padding30 {
  padding: 2rem 3rem 1rem;
}
.mb30-list {
  margin-bottom: 30px;
}
.mb30-list:last-child {
  margin-bottom: 0;
}
.min-height-none {
  min-height: inherit;
}
.line-height-none {
  line-height: 0;
}
.p-none {
  padding: 0;
}
.mb-none {
  margin-bottom: 0 !important;
}
.h3-mb-none-child h3,
.p-mb-none-child p {
  margin-bottom: 0;
}
.width-36 {
  width: 36%;
}
.width-40 {
  width: 40%;
}
.width-70 {
  width: 70%;
}
.width-85 {
  width: 85%;
}
.width-100 {
  width: 100%;
}
.add-item5-lg > div:nth-child(n + 5) {
  display: none;
}
@media (min-width: 768px) and (max-width: 991px) {
  .text-center-md {
    text-align: center;
  }
  .width40-md {
    width: 40%;
  }
  .width30-md {
    width: 30%;
  }
  .keep-items-4-md > div:nth-child(n + 5) {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .media-none-lg {
    display: block;
  }
  .media-none-lg > div {
    width: 100%;
  }
  .media-none-lg .media-padding15 {
    padding-left: 15px;
  }
  .media-none-lg .media-padding10 {
    padding-left: 10px;
  }
  .media-none-lg .media-body.media-margin30 {
    margin-left: 0;
    margin-top: 15px;
  }
  .block-lg li {
    display: block !important;
    width: 100% !important;
    margin-bottom: 3px;
  }
  .block-lg li:last-child {
    margin-bottom: 0;
  }
  .block-lg li a {
    width: 100%;
  }
  .width34-lg {
    width: 34%;
  }
  .width40-lg {
    width: 40%;
  }
  .width43-lg {
    width: 43%;
  }
  .width46-lg {
    width: 46%;
  }
  .width25-lg {
    width: 25%;
  }
  .width26-lg {
    width: 26%;
  }
  .width27-lg {
    width: 27%;
  }
  .width38-lg {
    width: 38%;
  }
  .show-item3-lg > div:nth-child(n + 4) {
    display: none;
  }
  .show-item4-lg > div:nth-child(n + 5) {
    display: none;
  }
  .show-item5-lg > div:nth-child(n + 6) {
    display: none;
  }
  .add-item5-lg > div:nth-child(n + 5) {
    display: flex;
  }
}
@media only screen and (max-width: 1199px) {
  h2 {
    font-size: 19px;
  }
  h2.size-sm {
    font-size: 17px;
  }
  h2.size-lg {
    font-size: 21px;
  }
  h2.size-xl {
    font-size: 23px;
  }
  h3 {
    font-size: 15px;
  }
  h3.size-sm {
    font-size: 13px;
  }
  h3.size-md {
    font-size: 14px;
  }
  h3.size-lg {
    font-size: 17px;
  }
  .media-none--lg {
    display: block;
  }
  .media-none--lg > div {
    width: 100%;
  }
  .media-none--lg .media-padding15 {
    padding-left: 15px;
  }
  .media-none--lg .media-padding10 {
    padding-left: 10px;
  }
  .media-none--lg .media-body.media-margin30 {
    margin-left: 0;
    margin-top: 15px;
  }
  .width80--lg {
    width: 80%;
  }
  .sidebar-break-lg {
    margin-top: 4rem;
  }
}
@media only screen and (max-width: 991px) {
  h2 {
    font-size: 18px;
  }
  h2.size-sm {
    font-size: 16px;
  }
  h2.size-lg {
    font-size: 20px;
  }
  h2.size-xl {
    font-size: 22px;
  }
  h3 {
    font-size: 14px;
  }
  h3.size-lg {
    font-size: 16px;
  }
  .text-center--md {
    text-align: center;
  }
  .size-c22 {
    font-size: 20px;
  }
  .size-c24,
  .size-c26 {
    font-size: 22px;
  }
  .size-c30 {
    font-size: 24px;
  }
  .size-c34,
  .size-c36,
  .size-c40 {
    font-size: 28px;
  }
  .size-c60 {
    font-size: 40px;
  }
  .media-none--md {
    display: block;
  }
  .media-none--md > div {
    width: 100%;
  }
  .media-none--md .media-padding15 {
    padding-left: 15px;
  }
  .media-none--md .media-padding10 {
    padding-left: 10px;
  }
  .media-none--md .media-body {
    margin-left: 0;
  }
  .media-none--md .media-body.media-margin30 {
    margin-left: 0;
    margin-top: 15px;
  }
  .sidebar-break-md {
    margin-top: 4rem;
  }
  .on-mobile-fixed {
    position: fixed;
    top: 20px;
  }
  .on-mobile-fixed ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
@media only screen and (max-width: 767px) {
  h1 {
    font-size: 22px;
  }
  h2 {
    font-size: 17px;
  }
  h2.size-lg {
    font-size: 19px;
  }
  h2.size-xl {
    font-size: 21px;
  }
  h3 {
    font-size: 13px;
  }
  .text-center--sm {
    text-align: center;
  }
  .media-none--sm {
    display: block;
  }
  .media-none--sm > div {
    width: 100%;
  }
  .media-none--sm .media-padding15 {
    padding-left: 15px;
  }
  .media-none--sm .media-padding10 {
    padding-left: 10px;
  }
  .media-none--sm .media-body.media-padding30 {
    padding-left: 15px;
    margin-left: 0;
  }
  .media-none--sm .media-body {
    display: inline-block;
    margin-left: 0;
  }
  .media-none--sm .media-body.media-margin30 {
    margin-left: 0;
    margin-top: 15px;
  }
  .size-c22 {
    font-size: 18px;
  }
  .size-c24,
  .size-c26 {
    font-size: 20px;
  }
  .size-c30 {
    font-size: 22px;
  }
  .size-c34 {
    font-size: 26px;
  }
  .size-c36,
  .size-c40 {
    font-size: 24px;
  }
  .size-c60 {
    font-size: 36px;
  }
}
@media only screen and (max-width: 575px) {
  h1 {
    font-size: 20px;
  }
  .text-center--xs {
    text-align: center !important;
  }
  .mb10--xs {
    margin-bottom: 10px !important;
  }
  .mb15--xs {
    margin-bottom: 15px;
  }
  .pl20-xs {
    padding-left: 20px;
  }
  .hidden-xs {
    display: none !important;
  }
  .media-none-xs {
    display: block;
  }
  .media-none-xs img {
    max-width: 100%;
  }
  .media-none-xs .media-padding10 {
    padding-left: 10px;
  }
  .media-none-xs .media-padding15 {
    padding-left: 15px;
  }
  .media-none-xs .media-padding8 {
    padding-left: 8px;
  }
  .media-none-xs .media-body.media-margin30 {
    margin-left: 0;
    margin-top: 15px;
  }
  .block-xs li {
    display: block !important;
    width: 100% !important;
    margin-bottom: 3px;
  }
  .block-xs li:last-child {
    margin-bottom: 0;
  }
  .block-xs li a {
    width: 100%;
  }
  .size-c36 {
    font-size: 22px;
  }
  .size-c40 {
    font-size: 18px;
  }
}
